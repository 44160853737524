import React from "react";
import { Link } from "react-router-dom";
import allProductsData from "../data/allProductsData";

const defaultImg = "https://furralumi.com/wp-content/uploads/2022/05/Fotot-do-te-shtohen-se-shpejti_2.jpg";

// First example
// const filteredByIdRelatedProducts = allProductsData.filter((item) => item.id === 154 || item.id === 14);
// const filteredByIdRelatedProducts = allProductsData.filter((item) => relatedIds.includes(item.id));
// console.log("filtered related products", filteredByIdRelatedProducts);

function RelatedProducts({ props }) {
  const filteredByIdRelatedProducts = allProductsData.filter((item) => props.includes(item.id));

  return (
    <>
      <div className="container mt-5">
        <div className="p-0 rounded rounded-3 ">
          <h5 className="pb-3">Produkte të ngjashme</h5>
          <div className="row ">
            {filteredByIdRelatedProducts.map((product, index) => {
              return (
                <>
                  <Link to={"/products/" + product.slug} exact className="text-dark text-decoration-none col-6 col-sm-6 col-md-4 col-xl-3 px-1 px-md-2">
                    <div className="p-2 mb-3 mb-md-4 shadow-sm rounded bg-white">
                      <div
                        className="ratio ratio-4x3  rounded"
                        style={
                          product.images[0]
                            ? { "backgroundImage": `url(${product.images[0].src})`, "backgroundSize": "cover", "backgroundPosition": "center", "--bs-aspect-ratio": "65%" }
                            : { "backgroundImage": `url(${defaultImg})`, "backgroundSize": "cover", "backgroundPosition": "center", "--bs-aspect-ratio": "65%" }
                        }
                      />
                      {/* <p>{product.id}</p> */}
                      {/* <img alt=""  src={product.images[0]}></img> */}
                      {/* <img alt=""  src={defaultImg} style={{ width: "150px" }}></img> */}
                      <p className="mb-0 mt-2 fw-semibold">{product.name}</p>
                      <p className="p-0 m-0">{(product.price * 1).toFixed(2)} €</p>
                    </div>
                  </Link>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default RelatedProducts;
