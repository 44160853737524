const aboutUs_1 = "https://www.cms.furralumi.com/wp-content/uploads/2023/03/tetor-097.jpg";
const aboutUs_2 = "https://www.cms.furralumi.com/wp-content/uploads/2023/03/tetor-124.jpg";
const aboutUs_3 = "https://www.cms.furralumi.com/wp-content/uploads/2023/03/tetori-me-walterin-047.jpg";

function AboutUs() {
  return (
    <>
      <div>
        <div className="container py-5">
          <div className="row">
            <div className="col-12 col-lg-8">
              <img alt="" src={aboutUs_1} className="img-fluid rounded-4 ratio ratio-1x1"></img>
            </div>
            <div className="col-10 col-lg-4 p-4 shadow m-auto rounded-4 ms-n1">
              <p className="m-0">
                Për një jetë të lumtur, ne besojmë se çdo ditë duhet të dashurohet. Si në punë, kohë të lirë, poashtu, edhe në ushqim. Prandaj, në vitin 1996 ne kemi zgjedhur t’a përçojmë dashurinë që
                e kemi për ushqimin deri tek secili prej jush që e kalon pragun e derës.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container py-5">
          <div className="row">
            <div className="col-10 col-lg-4 p-4 shadow m-auto rounded-4">
              <p className="m-0">
                Përkrahja nga mijëra persona si vendas poashtu edhe nga ata që kanë qenë të panjohur me kulturën Shqiptare na kultivoi dashurinë për këtë profesion. Shumë reçeta na janë ofruar me
                qëllimin e vetëm – të guxojmë t’i rikrijojmë mrekullirat e paraardhësve të tyre.
              </p>
            </div>
            <div className="col-12 col-lg-8">
              <img alt="" src={aboutUs_2} className="img-fluid rounded-4 ratio ratio-1x1"></img>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container py-5">
          <div className="row">
            <div className="col-12 col-lg-7">
              <img alt="" src={aboutUs_3} className="img-fluid rounded-4 ratio ratio-1x1"></img>
            </div>
            <div className="col-10 col-lg-5 p-4 shadow m-auto rounded-4 ms-n1">
              <p className="m-0">Si rezultat, ne jemi dalluar me shumëllojshmërinë e produkteve tradicionale e poashtu, të atyre që vijnë nga shumë tokë të huaja për ne.</p>
              <br />
              <p className="m-0">
                Përderisa për shumë fusha tjera të punës sfida është tek përcjellja e teknologjisë së re, ne mburremi që shquhemi si Furra Artizanale Lumi. Stili punë dore na lejon që çdo ditë të
                mbikqyrim lëndën e parë që e përdorim dhe kualitetin e produktit final. Si për vetveten, edhe për ju besojmë se mënyra më e mirë për mirëmbajtjen e shëndetit është ushqimi kualitativ.
                Sepse, shija më e mire në vend nuk duhet të vie me sakrifica në shëndet.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div></div>
      <div>
        <div className="container">
          <div className="py-5">
            <div className="py-5">
              <div className=" row justify-content-center">
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-chat-square-quote" viewBox="0 0 16 16">
                  <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                  <path d="M7.066 4.76A1.665 1.665 0 0 0 4 5.668a1.667 1.667 0 0 0 2.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 1 0 .6.58c1.486-1.54 1.293-3.214.682-4.112zm4 0A1.665 1.665 0 0 0 8 5.668a1.667 1.667 0 0 0 2.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 1 0 .6.58c1.486-1.54 1.293-3.214.682-4.112z" />
                </svg> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24">
                  <path
                    fill="#e6e9ec"
                    d="M17.175 17q-.75 0-1.15-.637q-.4-.638-.05-1.313L17 13h-2q-.825 0-1.412-.588Q13 11.825 13 11V8q0-.825.588-1.412Q14.175 6 15 6h3q.825 0 1.413.588Q20 7.175 20 8v4.525q0 .225-.038.463q-.037.237-.162.437l-1.425 2.825q-.175.35-.5.55q-.325.2-.7.2Zm-9 0q-.75 0-1.15-.637q-.4-.638-.05-1.313L8 13H6q-.825 0-1.412-.588Q4 11.825 4 11V8q0-.825.588-1.412Q5.175 6 6 6h3q.825 0 1.413.588Q11 7.175 11 8v4.525q0 .225-.037.463q-.038.237-.163.437L9.375 16.25q-.175.35-.5.55q-.325.2-.7.2Z"
                  />
                </svg>
              </div>
              <p className="fs-2 fst-italic pt-4 text-center mx-auto" style={{ maxWidth: "550px", color: "#97694e" }}>
                Tani nuk keni nevojë ta kaloni pragun për të shijuar ushqimet tona.
              </p>
              <p className="fw-bolder fs-5 pt-2 text-center mx-auto" style={{ color: "#97694e" }}>
                Porosit Tani
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
