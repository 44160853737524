import { message } from "antd";
import { createContext, useState, useEffect } from "react";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => {
    const storedCart = localStorage.getItem("cart");
    return storedCart ? JSON.parse(storedCart) : [];
  });

  const [reducedCart, setReducedCart] = useState([]);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
    setReducedCart(
      cart.map((item) => {
        return {
          product_id: item.product_id,
          variation_id: item.variation_id,
          quantity: item.quantity * item.step,
        };
      })
    );
  }, [cart]);
  console.log(" cartComponent", cart);

  const addItemToCart = (item, step, quantityToAdd) => {
    const existingItemIndex = cart.findIndex((cart) => cart.product_id === item.product_id && cart.variation_id === item.variation_id);

    const currentStockInCart =
      existingItemIndex === -1
        ? 0
        : item.manage_stock === true
        ? cart[existingItemIndex].quantity
        : cart.reduce((total, cart) => {
            if (cart.id === item.id) {
              return total + cart.quantity * (cart.step * 1);
            }
          }, 0);

    console.log("Current stock in cart", currentStockInCart);
    const itemInStock = item.stock - currentStockInCart >= item.step * 1 * quantityToAdd;

    const totalQuantity = quantityToAdd ? currentStockInCart + quantityToAdd * item.step * 1 : currentStockInCart + 1;
    console.log("root cart:", item);
    // console.log("cart", cart);
    // console.log("item", item);
    // console.log("Existing item?", existingItemIndex);
    // console.log("Is item in stock?", itemInStock);
    // console.log("Current stock in cart", currentStockInCart);
    if (totalQuantity > item.stock) {
      message.warning("Nuk ka stok!");
    } else {
      if (existingItemIndex !== -1) {
        if (itemInStock) {
          // Item already exists in the cart, increment its quantity
          const updatedCart = [...cart];
          updatedCart[existingItemIndex].quantity += quantityToAdd;
          updatedCart[existingItemIndex].variation_name = item.variation_name;
          setCart(updatedCart);
          message.success(item.name + (item.variation_name === "" ? "" : " - " + item.variation_name) + ", stoku në shportë u përditsua " + (quantityToAdd > 0 ? "+" + quantityToAdd : ""));
        } else {
          message.warning(item.name + " nuk ka mëshum në stok!");
        }
      } else {
        // message.warning("Nuk ka stok mëshum");
        setCart([...cart, item]);
        message.success("U shtua në shportë!");
      }
    }
  };

  //   This code removes products based on their unique ID
  //   const removeItemFromCart = (itemId) => {
  //     setCart(cart.filter((item) => item.id !== itemId));
  //   };

  const removeItemFromCart = (itemIndex) => {
    setCart(cart.filter((_, index) => index !== itemIndex));
  };

  const clearCart = () => {
    setCart([]);
  };

  const cartSize = cart.length;

  const cartSum = cart.reduce((total, item) => {
    return total + item.quantity * item.price * item.step;
  }, 0);

  return <CartContext.Provider value={{ cart, addItemToCart, removeItemFromCart, clearCart, cartSize, reducedCart, cartSum }}>{children}</CartContext.Provider>;
};
