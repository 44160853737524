import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";

const WooAPI = new WooCommerceRestApi({
  url: "https://cms.furralumi.com",
  consumerKey: "ck_4a84f47a47cd79d4a622e3007e822cf3246d64c3",
  consumerSecret: "cs_35e53e35fe4cd9dda51f78601c3037de2c60afff",
  version: "wc/v3",
});

export default WooAPI;
