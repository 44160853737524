const allProductsData = [
  {
    id: 149,
    name: "Manti",
    slug: "manti-2",
    permalink: "https://www.cms.furralumi.com/product/manti-2/",
    date_created: "2023-02-11T15:25:16",
    date_created_gmt: "2023-02-11T14:25:16",
    date_modified: "2023-03-30T19:36:37",
    date_modified_gmt: "2023-03-30T18:36:37",
    type: "variable",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "<p>Përshkrimi i produktit do të shtohet në kohën më të shpejtë të mundshme.</p>\n",
    short_description: "",
    sku: "00005-1-3",
    price: "0.24",
    regular_price: "",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 5,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 345,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "9",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 24,
        name: "Drekë",
        slug: "dreke",
      },
    ],
    tags: [],
    images: [
      {
        id: 98,
        date_created: "2023-02-11T15:24:25",
        date_created_gmt: "2023-02-11T14:24:25",
        date_modified: "2023-02-11T15:24:25",
        date_modified_gmt: "2023-02-11T14:24:25",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/mantia-scaled-2.jpg",
        name: "mantia-scaled-2.jpg",
        alt: "",
      },
      {
        id: 99,
        date_created: "2023-02-11T15:24:28",
        date_created_gmt: "2023-02-11T14:24:28",
        date_modified: "2023-02-11T15:24:28",
        date_modified_gmt: "2023-02-11T14:24:28",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/mantia_1-scaled-1.jpg",
        name: "mantia_1-scaled-1.jpg",
        alt: "",
      },
    ],
    attributes: [
      {
        id: 0,
        name: "Sasia",
        position: 0,
        visible: true,
        variation: true,
        options: ["Copë", "Tepsi e vogël", "Tepsi e mesme", "Tepsi e madhe"],
      },
    ],
    default_attributes: [],
    variations: [150, 151, 152, 153],
    grouped_products: [],
    menu_order: 0,
    price_html: '<span class="woocommerce-Price-amount amount"><bdi>0.24<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [45, 28, 40, 154, 36],
    meta_data: [],
    stock_status: "instock",
    has_options: true,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/149",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 60,
    name: "Tortë Ferrari",
    slug: "torte-ferrari",
    permalink: "https://www.cms.furralumi.com/product/torte-ferrari/",
    date_created: "2023-02-11T15:23:59",
    date_created_gmt: "2023-02-11T14:23:59",
    date_modified: "2023-03-30T17:24:28",
    date_modified_gmt: "2023-03-30T16:24:28",
    type: "variable",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "00027",
    price: "15",
    regular_price: "",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 10,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 2,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "1",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 27,
        name: "Tortë",
        slug: "torte",
      },
    ],
    tags: [],
    images: [
      {
        id: 119,
        date_created: "2023-02-11T15:24:54",
        date_created_gmt: "2023-02-11T14:24:54",
        date_modified: "2023-02-11T15:24:54",
        date_modified_gmt: "2023-02-11T14:24:54",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/torte-ferrari.jpg",
        name: "torte-ferrari.jpg",
        alt: "",
      },
      {
        id: 120,
        date_created: "2023-02-11T15:24:56",
        date_created_gmt: "2023-02-11T14:24:56",
        date_modified: "2023-02-11T15:24:56",
        date_modified_gmt: "2023-02-11T14:24:56",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/torte-ferrari_1.jpg",
        name: "torte-ferrari_1.jpg",
        alt: "",
      },
    ],
    attributes: [
      {
        id: 4,
        name: "Madhësia",
        position: 0,
        visible: true,
        variation: true,
        options: ["e madhe", "e mesme"],
      },
    ],
    default_attributes: [],
    variations: [108, 109],
    grouped_products: [],
    menu_order: 0,
    price_html:
      '<span class="woocommerce-Price-amount amount"><bdi>15.00<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span> &ndash; <span class="woocommerce-Price-amount amount"><bdi>24.00<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [64, 61, 62],
    meta_data: [],
    stock_status: "outofstock",
    has_options: true,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/60",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 62,
    name: "Tortë Jaffa",
    slug: "torte-jaffa",
    permalink: "https://www.cms.furralumi.com/product/torte-jaffa/",
    date_created: "2023-02-11T15:23:59",
    date_created_gmt: "2023-02-11T14:23:59",
    date_modified: "2023-03-30T17:10:50",
    date_modified_gmt: "2023-03-30T16:10:50",
    type: "variable",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "00025",
    price: "16.00",
    regular_price: "",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 4,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 0,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "1",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 27,
        name: "Tortë",
        slug: "torte",
      },
    ],
    tags: [],
    images: [
      {
        id: 132,
        date_created: "2023-02-11T15:25:04",
        date_created_gmt: "2023-02-11T14:25:04",
        date_modified: "2023-02-11T15:25:04",
        date_modified_gmt: "2023-02-11T14:25:04",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/torte-jaffa_2.jpg",
        name: "torte-jaffa_2.jpg",
        alt: "",
      },
      {
        id: 133,
        date_created: "2023-02-11T15:25:06",
        date_created_gmt: "2023-02-11T14:25:06",
        date_modified: "2023-02-11T15:25:06",
        date_modified_gmt: "2023-02-11T14:25:06",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/torte-jaffa_4.jpg",
        name: "torte-jaffa_4.jpg",
        alt: "",
      },
    ],
    attributes: [
      {
        id: 0,
        name: "Sasia",
        position: 0,
        visible: true,
        variation: true,
        options: ["E mesme", "E madhe"],
      },
    ],
    default_attributes: [],
    variations: [66, 67],
    grouped_products: [],
    menu_order: 0,
    price_html:
      '<span class="woocommerce-Price-amount amount"><bdi>16.00<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span> &ndash; <span class="woocommerce-Price-amount amount"><bdi>25.00<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [64, 61, 60],
    meta_data: [],
    stock_status: "outofstock",
    has_options: true,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/62",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 64,
    name: "Tortë Millka",
    slug: "torte-millka",
    permalink: "https://www.cms.furralumi.com/product/torte-millka/",
    date_created: "2023-02-11T15:23:59",
    date_created_gmt: "2023-02-11T14:23:59",
    date_modified: "2023-03-30T16:55:25",
    date_modified_gmt: "2023-03-30T15:55:25",
    type: "variable",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "00023",
    price: "15",
    regular_price: "",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 8,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: -4,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "1",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 27,
        name: "Tortë",
        slug: "torte",
      },
    ],
    tags: [],
    images: [
      {
        id: 134,
        date_created: "2023-02-11T15:25:07",
        date_created_gmt: "2023-02-11T14:25:07",
        date_modified: "2023-02-11T15:25:07",
        date_modified_gmt: "2023-02-11T14:25:07",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/torte-millka_6.jpg",
        name: "torte-millka_6.jpg",
        alt: "",
      },
      {
        id: 135,
        date_created: "2023-02-11T15:25:09",
        date_created_gmt: "2023-02-11T14:25:09",
        date_modified: "2023-02-11T15:25:09",
        date_modified_gmt: "2023-02-11T14:25:09",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/torte-millka_2.jpg",
        name: "torte-millka_2.jpg",
        alt: "",
      },
      {
        id: 136,
        date_created: "2023-02-11T15:25:10",
        date_created_gmt: "2023-02-11T14:25:10",
        date_modified: "2023-02-11T15:25:10",
        date_modified_gmt: "2023-02-11T14:25:10",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/torte-millka_4.jpg",
        name: "torte-millka_4.jpg",
        alt: "",
      },
      {
        id: 137,
        date_created: "2023-02-11T15:25:12",
        date_created_gmt: "2023-02-11T14:25:12",
        date_modified: "2023-02-11T15:25:12",
        date_modified_gmt: "2023-02-11T14:25:12",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/torte-millka_5.jpg",
        name: "torte-millka_5.jpg",
        alt: "",
      },
    ],
    attributes: [
      {
        id: 4,
        name: "Madhësia",
        position: 0,
        visible: true,
        variation: true,
        options: ["e madhe", "e mesme"],
      },
    ],
    default_attributes: [],
    variations: [69, 68],
    grouped_products: [],
    menu_order: 0,
    price_html:
      '<span class="woocommerce-Price-amount amount"><bdi>15.00<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span> &ndash; <span class="woocommerce-Price-amount amount"><bdi>24.00<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [62, 61, 60],
    meta_data: [],
    stock_status: "outofstock",
    has_options: true,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/64",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 61,
    name: "Tortë Grilazh",
    slug: "torte-grilazh",
    permalink: "https://www.cms.furralumi.com/product/torte-grilazh/",
    date_created: "2023-02-11T15:23:59",
    date_created_gmt: "2023-02-11T14:23:59",
    date_modified: "2023-03-30T17:09:47",
    date_modified_gmt: "2023-03-30T16:09:47",
    type: "variable",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "00031",
    price: "15",
    regular_price: "",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 64,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: -60,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "",
    dimensions: {
      length: "",
      width: "",
      height: "1",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 27,
        name: "Tortë",
        slug: "torte",
      },
    ],
    tags: [],
    images: [
      {
        id: 121,
        date_created: "2023-02-11T15:24:57",
        date_created_gmt: "2023-02-11T14:24:57",
        date_modified: "2023-02-11T15:24:57",
        date_modified_gmt: "2023-02-11T14:24:57",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/torte-grilazh_2.jpg",
        name: "torte-grilazh_2.jpg",
        alt: "",
      },
      {
        id: 122,
        date_created: "2023-02-11T15:24:59",
        date_created_gmt: "2023-02-11T14:24:59",
        date_modified: "2023-02-11T15:24:59",
        date_modified_gmt: "2023-02-11T14:24:59",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/torte-grilazh.jpg",
        name: "torte-grilazh.jpg",
        alt: "",
      },
      {
        id: 123,
        date_created: "2023-02-11T15:25:00",
        date_created_gmt: "2023-02-11T14:25:00",
        date_modified: "2023-02-11T15:25:00",
        date_modified_gmt: "2023-02-11T14:25:00",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/torte-grilazh_3.jpg",
        name: "torte-grilazh_3.jpg",
        alt: "",
      },
    ],
    attributes: [
      {
        id: 4,
        name: "Madhësia",
        position: 0,
        visible: true,
        variation: true,
        options: ["e madhe", "e mesme"],
      },
    ],
    default_attributes: [],
    variations: [90, 91],
    grouped_products: [],
    menu_order: 0,
    price_html:
      '<span class="woocommerce-Price-amount amount"><bdi>15.00<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span> &ndash; <span class="woocommerce-Price-amount amount"><bdi>24.00<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [60, 64, 62],
    meta_data: [],
    stock_status: "outofstock",
    has_options: true,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/61",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 63,
    name: "Tortë me Banane",
    slug: "torte-me-banane",
    permalink: "https://www.cms.furralumi.com/product/torte-me-banane/",
    date_created: "2023-02-11T15:23:59",
    date_created_gmt: "2023-02-11T14:23:59",
    date_modified: "2023-02-11T15:25:18",
    date_modified_gmt: "2023-02-11T14:25:18",
    type: "variable",
    status: "private",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "00030",
    price: "",
    regular_price: "",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: false,
    total_sales: 0,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 20,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "1",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 27,
        name: "Tortë",
        slug: "torte",
      },
    ],
    tags: [],
    images: [
      {
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/04/Fotot-do-te-shtohen-se-shpejti_2.jpg",
        name: "default",
        alt: "",
      },
    ],
    attributes: [
      {
        id: 4,
        name: "Madhësia",
        position: 0,
        visible: true,
        variation: true,
        options: ["e madhe", "e mesme"],
      },
    ],
    default_attributes: [],
    variations: [139, 140],
    grouped_products: [],
    menu_order: 0,
    price_html: "",
    related_ids: [64, 61, 62, 60],
    meta_data: [],
    stock_status: "instock",
    has_options: true,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/63",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 43,
    name: "Rafaello",
    slug: "rafaello",
    permalink: "https://www.cms.furralumi.com/product/rafaello/",
    date_created: "2023-02-11T15:23:38",
    date_created_gmt: "2023-02-11T14:23:38",
    date_modified: "2023-02-11T15:24:48",
    date_modified_gmt: "2023-02-11T14:24:48",
    type: "simple",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "00046",
    price: "1.55",
    regular_price: "1.55",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 3,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: false,
    stock_quantity: null,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "1",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 22,
        name: "Ëmbëlsirat individuale",
        slug: "embelsirat-individuale",
      },
    ],
    tags: [],
    images: [
      {
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/04/Fotot-do-te-shtohen-se-shpejti_2.jpg",
        name: "default",
        alt: "",
      },
    ],
    attributes: [],
    default_attributes: [],
    variations: [],
    grouped_products: [],
    menu_order: 0,
    price_html: '<span class="woocommerce-Price-amount amount"><bdi>1.55<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [44, 39, 19, 46, 25],
    meta_data: [],
    stock_status: "instock",
    has_options: false,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/43",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 42,
    name: "Princess Krofne",
    slug: "princess-krofne",
    permalink: "https://www.cms.furralumi.com/product/princess-krofne/",
    date_created: "2023-02-11T15:23:38",
    date_created_gmt: "2023-02-11T14:23:38",
    date_modified: "2023-03-05T01:41:20",
    date_modified_gmt: "2023-03-05T00:41:20",
    type: "variable",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "00008",
    price: "0.95",
    regular_price: "",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 1,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 7,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "1",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 22,
        name: "Ëmbëlsirat individuale",
        slug: "embelsirat-individuale",
      },
    ],
    tags: [],
    images: [
      {
        id: 113,
        date_created: "2023-02-11T15:24:45",
        date_created_gmt: "2023-02-11T14:24:45",
        date_modified: "2023-02-11T15:24:45",
        date_modified_gmt: "2023-02-11T14:24:45",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/princess-krofne.jpg",
        name: "princess-krofne.jpg",
        alt: "",
      },
      {
        id: 114,
        date_created: "2023-02-11T15:24:46",
        date_created_gmt: "2023-02-11T14:24:46",
        date_modified: "2023-02-11T15:24:46",
        date_modified_gmt: "2023-02-11T14:24:46",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/princess-krofne_1.jpg",
        name: "princess-krofne_1.jpg",
        alt: "",
      },
    ],
    attributes: [
      {
        id: 3,
        name: "Sasia",
        position: 0,
        visible: true,
        variation: true,
        options: ["copë"],
      },
    ],
    default_attributes: [
      {
        id: 3,
        name: "Sasia",
        option: "cope",
      },
    ],
    variations: [92],
    grouped_products: [],
    menu_order: 0,
    price_html: '<span class="woocommerce-Price-amount amount"><bdi>0.95<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [26, 44, 46, 25, 23],
    meta_data: [],
    stock_status: "instock",
    has_options: true,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/42",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 44,
    name: "Rosen",
    slug: "rosen",
    permalink: "https://www.cms.furralumi.com/product/rosen/",
    date_created: "2023-02-11T15:23:38",
    date_created_gmt: "2023-02-11T14:23:38",
    date_modified: "2023-02-11T15:24:48",
    date_modified_gmt: "2023-02-11T14:24:48",
    type: "simple",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "00003",
    price: "0.6",
    regular_price: "0.6",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 0,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 50,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "1",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 22,
        name: "Ëmbëlsirat individuale",
        slug: "embelsirat-individuale",
      },
    ],
    tags: [],
    images: [
      {
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/04/Fotot-do-te-shtohen-se-shpejti_2.jpg",
        name: "default",
        alt: "",
      },
    ],
    attributes: [],
    default_attributes: [],
    variations: [],
    grouped_products: [],
    menu_order: 0,
    price_html: '<span class="woocommerce-Price-amount amount"><bdi>0.60<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [43, 26, 21, 20, 39],
    meta_data: [],
    stock_status: "instock",
    has_options: false,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/44",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 45,
    name: "Samse",
    slug: "samse",
    permalink: "https://www.cms.furralumi.com/product/samse/",
    date_created: "2023-02-11T15:23:38",
    date_created_gmt: "2023-02-11T14:23:38",
    date_modified: "2023-02-11T15:25:15",
    date_modified_gmt: "2023-02-11T14:25:15",
    type: "variable",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description:
      "<p>Ne i shesim produktet tona në mënyrë që ju të bleni aq sa i konsumoni. Prandaj samsa/bakllasarma llogaritet në bazë të peshës. Kështu ju mund të përzgjedhni edhe produkte tjera të cilat ua kompletojnë një drekë tradicionale me shumëllojshmëri.</p>\n<p>Tepsia e madhe peshon 2kg dhe shërben 5 persona<br />\n1.6 kg - 4 persona<br />\n1.2 kg - 3 persona<br />\n0.8 kg - 2 persona<br />\n0.4 kg - 1 person</p>\n<p>Këto më lartë janë llogaritur si porcione të mëdha.</p>\n<p>250gr - porcion i vogël<br />\n325gr - porcion i mesëm<br />\n375gr - porcion i madh<br />\n500gr - kujdes se garant ka me t’tepru pak...</p>\n<p>Nëse dëshironi ta merrni me tepsi, ju lutem shtoni 2kg për secilën tepsi që e dëshironi. Pastaj e CEKNI tek detajet e porosisë nëse dëshironi ti preni në ndonjë mënyrë të caktuar. Përndryshe ne i paketojmë në paketimet tona transportuese.</p>\n<p>Ju lutem keni parasysh se për ta marrë me tepsi duhet të lini avans prej 10€ për secilën tepsi që e merrni. Avansi kthehet kur e ktheni tepsinë.</p>\n",
    short_description: "",
    sku: "00037",
    price: "5",
    regular_price: "",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 0,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 43,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "1",
    dimensions: {
      length: "",
      width: "",
      height: "1",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 24,
        name: "Drekë",
        slug: "dreke",
      },
    ],
    tags: [],
    images: [
      {
        id: 115,
        date_created: "2023-02-11T15:24:48",
        date_created_gmt: "2023-02-11T14:24:48",
        date_modified: "2023-02-11T15:24:48",
        date_modified_gmt: "2023-02-11T14:24:48",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/samse_1.jpg",
        name: "samse_1.jpg",
        alt: "",
      },
      {
        id: 116,
        date_created: "2023-02-11T15:24:50",
        date_created_gmt: "2023-02-11T14:24:50",
        date_modified: "2023-02-11T15:24:50",
        date_modified_gmt: "2023-02-11T14:24:50",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/samse_4.jpg",
        name: "samse_4.jpg",
        alt: "",
      },
      {
        id: 117,
        date_created: "2023-02-11T15:24:51",
        date_created_gmt: "2023-02-11T14:24:51",
        date_modified: "2023-02-11T15:24:51",
        date_modified_gmt: "2023-02-11T14:24:51",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/samse_5.jpg",
        name: "samse_5.jpg",
        alt: "",
      },
      {
        id: 118,
        date_created: "2023-02-11T15:24:52",
        date_created_gmt: "2023-02-11T14:24:52",
        date_modified: "2023-02-11T15:24:52",
        date_modified_gmt: "2023-02-11T14:24:52",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/samse_8.jpg",
        name: "samse_8.jpg",
        alt: "",
      },
    ],
    attributes: [
      {
        id: 3,
        name: "Sasia",
        position: 0,
        visible: true,
        variation: true,
        options: ["me kilogram", "tepsi e mesme"],
      },
    ],
    default_attributes: [],
    variations: [85, 86],
    grouped_products: [],
    menu_order: 0,
    price_html: '<span class="woocommerce-Price-amount amount"><bdi>5.00<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [28, 41, 35, 40, 149],
    meta_data: [],
    stock_status: "instock",
    has_options: true,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/45",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 46,
    name: "Schwarzwald",
    slug: "schwarzwald",
    permalink: "https://www.cms.furralumi.com/product/schwarzwald/",
    date_created: "2023-02-11T15:23:38",
    date_created_gmt: "2023-02-11T14:23:38",
    date_modified: "2023-02-11T15:24:54",
    date_modified_gmt: "2023-02-11T14:24:54",
    type: "simple",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "00047",
    price: "1.45",
    regular_price: "1.45",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 0,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: false,
    stock_quantity: null,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "1",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 22,
        name: "Ëmbëlsirat individuale",
        slug: "embelsirat-individuale",
      },
    ],
    tags: [],
    images: [
      {
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/04/Fotot-do-te-shtohen-se-shpejti_2.jpg",
        name: "default",
        alt: "",
      },
    ],
    attributes: [],
    default_attributes: [],
    variations: [],
    grouped_products: [],
    menu_order: 0,
    price_html: '<span class="woocommerce-Price-amount amount"><bdi>1.45<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [23, 42, 25, 26, 39],
    meta_data: [],
    stock_status: "instock",
    has_options: false,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/46",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 38,
    name: "Manti",
    slug: "manti",
    permalink: "https://www.cms.furralumi.com/product/manti/",
    date_created: "2023-02-11T15:23:37",
    date_created_gmt: "2023-02-11T14:23:37",
    date_modified: "2023-02-11T15:25:15",
    date_modified_gmt: "2023-02-11T14:25:15",
    type: "variable",
    status: "private",
    featured: false,
    catalog_visibility: "visible",
    description:
      "<p>Ne i shesim produktet tona në mënyrë që ju të bleni aq sa i konsumoni. Prandaj mantiat llogariten me copë. Kështu ju mund të përzgjedhni edhe produkte tjera të cilat ua kompletojnë një drekë tradicionale me shumëllojshmëri.</p>\n<p>Madhësia e shujtave:<br />\n7 mantia - porcion i vogël<br />\n10 mantia - porcion i mesëm<br />\n15 mantia - porcion i madh<br />\n20 mantia - kujdes se garant ka me t’tepru 1…</p>\n<p>Nëse dëshironi t’i merrni mantiat me tepsi, ju lutem zgjedhni aq copë sa ka secila tepsi dhe pastaj e CEKNI tek detajet e porosisë se dëshironi t’i bleni me tepsi. Përndryshe ne i paketojmë në paketimet tona transportuese.</p>\n<p>Tepsia e vogël 80 copë (drejtkëndëshe)<br />\nTepsia e mesme 130 copë (e rrumbullaktë)<br />\nTepsia e madhe 150 copë (drejtkëndëshe)</p>\n<p>Poashtu, p.sh. nëse dëshironi ta merrni 1 tepsi të madhe dhe një të vogël, zgjedhni shumën e atyre copëve pra 230 mantia në këtë rast dhe CEKNI tek detajet se dëshironi 1 tepsi të madhe dhe një të vogël. Përndryshe ne i paketojmë në paketimet tona transportuese.</p>\n<p>Ju lutem keni parasysh se për ta marrë me tepsi duhet të lini avans prej 10€ për secilën tepsi që e merrni. Avansi kthehet kur e ktheni edhe tepsinë.</p>\n",
    short_description: "",
    sku: "00005",
    price: "0.24",
    regular_price: "",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 0,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 89,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 24,
        name: "Drekë",
        slug: "dreke",
      },
    ],
    tags: [],
    images: [
      {
        id: 98,
        date_created: "2023-02-11T15:24:25",
        date_created_gmt: "2023-02-11T14:24:25",
        date_modified: "2023-02-11T15:24:25",
        date_modified_gmt: "2023-02-11T14:24:25",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/mantia-scaled-2.jpg",
        name: "mantia-scaled-2.jpg",
        alt: "",
      },
      {
        id: 99,
        date_created: "2023-02-11T15:24:28",
        date_created_gmt: "2023-02-11T14:24:28",
        date_modified: "2023-02-11T15:24:28",
        date_modified_gmt: "2023-02-11T14:24:28",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/mantia_1-scaled-1.jpg",
        name: "mantia_1-scaled-1.jpg",
        alt: "",
      },
    ],
    attributes: [
      {
        id: 0,
        name: "Sasia",
        position: 0,
        visible: true,
        variation: true,
        options: ["Copë", "Tepsi e vogël", "Tepsi e mesme", "Tepsi e madhe"],
      },
    ],
    default_attributes: [],
    variations: [65],
    grouped_products: [],
    menu_order: 0,
    price_html: '<span class="woocommerce-Price-amount amount"><bdi>0.24<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [36, 28, 35, 41, 154],
    meta_data: [],
    stock_status: "instock",
    has_options: true,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/38",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 41,
    name: "Pite me Spinaq",
    slug: "pite-me-spinaq",
    permalink: "https://www.cms.furralumi.com/product/pite-me-spinaq/",
    date_created: "2023-02-11T15:23:37",
    date_created_gmt: "2023-02-11T14:23:37",
    date_modified: "2023-03-30T19:10:10",
    date_modified_gmt: "2023-03-30T18:10:10",
    type: "variable",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description:
      "<p>Ne i shesim produktet tona në mënyrë që ju të bleni aq sa i konsumoni. Prandaj Pitja llogaritet me copë. Kështu ju mund të përzgjedhni edhe produkte tjera të cilat ua kompletojnë një drekë tradicionale me shumëllojshmëri.</p>\n<p>Tepsia e madhe përmban 12 pjesë.</p>\n<p>Shujtat llogariten si mëposhtë:</p>\n<p>1 pjesë - porcion i madh<br />\n2 copë - kujdes se garant ka me t’tepru pak...</p>\n<p>Nëse dëshironi ta merrni me tepsi, ju lutem shtoni 12 copë për secilën tepsi që e dëshironi. Pastaj e CEKNI tek detajet e porosisë nëse dëshironi ti paketoni në ndonjë mënyrë të caktuar. Përndryshe ne i paketojmë në paketimet tona transportuese.</p>\n<p>Ju lutem keni parasysh se për ta marrë me tepsi duhet të lini avans prej 10€ për secilën tepsi që e merrni. Avansi kthehet kur e ktheni tepsinë.</p>\n",
    short_description: "",
    sku: "00009",
    price: "2",
    regular_price: "",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 2,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 80,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "1",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 24,
        name: "Drekë",
        slug: "dreke",
      },
    ],
    tags: [],
    images: [
      {
        id: 111,
        date_created: "2023-02-11T15:24:42",
        date_created_gmt: "2023-02-11T14:24:42",
        date_modified: "2023-02-11T15:24:42",
        date_modified_gmt: "2023-02-11T14:24:42",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/pite-me-spinaq.jpg",
        name: "pite-me-spinaq.jpg",
        alt: "",
      },
      {
        id: 112,
        date_created: "2023-02-11T15:24:43",
        date_created_gmt: "2023-02-11T14:24:43",
        date_modified: "2023-02-11T15:24:43",
        date_modified_gmt: "2023-02-11T14:24:43",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/pite-me-spinaq_1.jpg",
        name: "pite-me-spinaq_1.jpg",
        alt: "",
      },
      {
        id: 111,
        date_created: "2023-02-11T15:24:42",
        date_created_gmt: "2023-02-11T14:24:42",
        date_modified: "2023-02-11T15:24:42",
        date_modified_gmt: "2023-02-11T14:24:42",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/pite-me-spinaq.jpg",
        name: "pite-me-spinaq.jpg",
        alt: "",
      },
    ],
    attributes: [
      {
        id: 3,
        name: "Sasia",
        position: 0,
        visible: true,
        variation: true,
        options: ["copë", "tepsi e madhe", "tepsi e mesme"],
      },
    ],
    default_attributes: [],
    variations: [87, 88, 89],
    grouped_products: [],
    menu_order: 0,
    price_html:
      '<span class="woocommerce-Price-amount amount"><bdi>2.00<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span> &ndash; <span class="woocommerce-Price-amount amount"><bdi>24.00<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [45, 28, 248, 154, 36],
    meta_data: [],
    stock_status: "instock",
    has_options: true,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/41",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 40,
    name: "Pite me Kungull",
    slug: "pite-me-kungull",
    permalink: "https://www.cms.furralumi.com/product/pite-me-kungull/",
    date_created: "2023-02-11T15:23:37",
    date_created_gmt: "2023-02-11T14:23:37",
    date_modified: "2023-03-30T16:52:58",
    date_modified_gmt: "2023-03-30T15:52:58",
    type: "variable",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description:
      "<p>Ne i shesim produktet tona në mënyrë që ju të bleni aq sa i konsumoni. Prandaj Pitja llogaritet me copë. Kështu ju mund të përzgjedhni edhe produkte tjera të cilat ua kompletojnë një drekë tradicionale me shumëllojshmëri.</p>\n<p>Tepsia e madhe përmban 12 pjesë.</p>\n<p>Shujtat llogariten si mëposhtë:</p>\n<p>1 pjesë - porcion i madh<br />\n2 copë - kujdes se garant ka me t’tepru pak...</p>\n<p>Nëse dëshironi ta merrni me tepsi, ju lutem shtoni 12 copë për secilën tepsi që e dëshironi. Pastaj e CEKNI tek detajet e porosisë nëse dëshironi ti paketoni në ndonjë mënyrë të caktuar. Përndryshe ne i paketojmë në paketimet tona transportuese.</p>\n<p>Ju lutem keni parasysh se për ta marrë me tepsi duhet të lini avans prej 10€ për secilën tepsi që e merrni. Avansi kthehet kur e ktheni tepsinë.</p>\n",
    short_description: "",
    sku: "00041",
    price: "2",
    regular_price: "",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 1,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 59,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "1",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 24,
        name: "Drekë",
        slug: "dreke",
      },
    ],
    tags: [],
    images: [
      {
        id: 104,
        date_created: "2023-02-11T15:24:37",
        date_created_gmt: "2023-02-11T14:24:37",
        date_modified: "2023-02-11T15:24:37",
        date_modified_gmt: "2023-02-11T14:24:37",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/pite-me-kungull_2.jpg",
        name: "pite-me-kungull_2.jpg",
        alt: "",
      },
      {
        id: 105,
        date_created: "2023-02-11T15:24:38",
        date_created_gmt: "2023-02-11T14:24:38",
        date_modified: "2023-02-11T15:24:38",
        date_modified_gmt: "2023-02-11T14:24:38",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/pite-me-kungull_1.jpg",
        name: "pite-me-kungull_1.jpg",
        alt: "",
      },
      {
        id: 106,
        date_created: "2023-02-11T15:24:40",
        date_created_gmt: "2023-02-11T14:24:40",
        date_modified: "2023-02-11T15:24:40",
        date_modified_gmt: "2023-02-11T14:24:40",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/pite-me-kungull.jpg",
        name: "pite-me-kungull.jpg",
        alt: "",
      },
    ],
    attributes: [
      {
        id: 3,
        name: "Sasia",
        position: 0,
        visible: true,
        variation: true,
        options: ["copë", "tepsi e madhe", "tepsi e mesme"],
      },
    ],
    default_attributes: [],
    variations: [93, 94, 107],
    grouped_products: [],
    menu_order: 0,
    price_html:
      '<span class="woocommerce-Price-amount amount"><bdi>2.00<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span> &ndash; <span class="woocommerce-Price-amount amount"><bdi>24.00<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [149, 154, 28, 41, 36],
    meta_data: [],
    stock_status: "instock",
    has_options: true,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/40",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 39,
    name: "Mascarpone",
    slug: "mascarpone",
    permalink: "https://www.cms.furralumi.com/product/mascarpone/",
    date_created: "2023-02-11T15:23:37",
    date_created_gmt: "2023-02-11T14:23:37",
    date_modified: "2023-03-19T02:10:23",
    date_modified_gmt: "2023-03-19T01:10:23",
    type: "simple",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "00010",
    price: "1.75",
    regular_price: "1.75",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 8,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 26,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "1",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 22,
        name: "Ëmbëlsirat individuale",
        slug: "embelsirat-individuale",
      },
    ],
    tags: [],
    images: [
      {
        id: 100,
        date_created: "2023-02-11T15:24:30",
        date_created_gmt: "2023-02-11T14:24:30",
        date_modified: "2023-02-11T15:24:30",
        date_modified_gmt: "2023-02-11T14:24:30",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/mascarpone.jpg",
        name: "mascarpone.jpg",
        alt: "",
      },
      {
        id: 101,
        date_created: "2023-02-11T15:24:32",
        date_created_gmt: "2023-02-11T14:24:32",
        date_modified: "2023-02-11T15:24:32",
        date_modified_gmt: "2023-02-11T14:24:32",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/mascarpone_1.jpg",
        name: "mascarpone_1.jpg",
        alt: "",
      },
      {
        id: 102,
        date_created: "2023-02-11T15:24:33",
        date_created_gmt: "2023-02-11T14:24:33",
        date_modified: "2023-02-11T15:24:33",
        date_modified_gmt: "2023-02-11T14:24:33",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/mascarpone_4.jpg",
        name: "mascarpone_4.jpg",
        alt: "",
      },
      {
        id: 103,
        date_created: "2023-02-11T15:24:35",
        date_created_gmt: "2023-02-11T14:24:35",
        date_modified: "2023-02-11T15:24:35",
        date_modified_gmt: "2023-02-11T14:24:35",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/mascarpone_3.jpg",
        name: "mascarpone_3.jpg",
        alt: "",
      },
    ],
    attributes: [
      {
        id: 3,
        name: "Sasia",
        position: 0,
        visible: true,
        variation: false,
        options: ["copë"],
      },
    ],
    default_attributes: [],
    variations: [],
    grouped_products: [],
    menu_order: 0,
    price_html: '<span class="woocommerce-Price-amount amount"><bdi>1.75<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [44, 19, 42, 23, 21],
    meta_data: [],
    stock_status: "instock",
    has_options: false,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/39",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 37,
    name: "Macarons paketim 5 copë",
    slug: "macarons-paketim-5-cope",
    permalink: "https://www.cms.furralumi.com/product/macarons-paketim-5-cope/",
    date_created: "2023-02-11T15:23:37",
    date_created_gmt: "2023-02-11T14:23:37",
    date_modified: "2023-03-28T17:06:56",
    date_modified_gmt: "2023-03-28T16:06:56",
    type: "simple",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "00050",
    price: "3",
    regular_price: "3",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 1,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 18,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 22,
        name: "Ëmbëlsirat individuale",
        slug: "embelsirat-individuale",
      },
    ],
    tags: [],
    images: [
      {
        id: 95,
        date_created: "2023-02-11T15:24:20",
        date_created_gmt: "2023-02-11T14:24:20",
        date_modified: "2023-02-11T15:24:20",
        date_modified_gmt: "2023-02-11T14:24:20",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/luxemburgerly_10.jpg",
        name: "luxemburgerly_10.jpg",
        alt: "",
      },
      {
        id: 96,
        date_created: "2023-02-11T15:24:22",
        date_created_gmt: "2023-02-11T14:24:22",
        date_modified: "2023-02-11T15:24:22",
        date_modified_gmt: "2023-02-11T14:24:22",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/luxemburgerly_12.jpg",
        name: "luxemburgerly_12.jpg",
        alt: "",
      },
      {
        id: 97,
        date_created: "2023-02-11T15:24:23",
        date_created_gmt: "2023-02-11T14:24:23",
        date_modified: "2023-02-11T15:24:23",
        date_modified_gmt: "2023-02-11T14:24:23",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/luxemburgerly_5.jpg",
        name: "luxemburgerly_5.jpg",
        alt: "",
      },
    ],
    attributes: [],
    default_attributes: [],
    variations: [],
    grouped_products: [],
    menu_order: 0,
    price_html: '<span class="woocommerce-Price-amount amount"><bdi>3.00<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [26, 46, 20, 23, 44],
    meta_data: [],
    stock_status: "instock",
    has_options: false,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/37",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 35,
    name: "Krelanë e kollomojt",
    slug: "krelane-e-kollomojt",
    permalink: "https://www.cms.furralumi.com/product/krelane-e-kollomojt/",
    date_created: "2023-02-11T15:23:37",
    date_created_gmt: "2023-02-11T14:23:37",
    date_modified: "2023-03-30T16:55:25",
    date_modified_gmt: "2023-03-30T15:55:25",
    type: "variable",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description:
      "<p>Ne i shesim produktet tona në mënyrë që ju të bleni aq sa i konsumoni. Prandaj Krelana llogaritet me copë. Kështu ju mund të përzgjedhni edhe produkte tjera të cilat ua kompletojnë një drekë tradicionale me shumëllojshmëri.</p>\n<p>Tepsia e madhe përmban 20 pjesë.</p>\n<p>Shujtat llogariten si mëposhtë:</p>\n<p>1 pjesë - porcion i vogël<br />\n2 copë - porcion i mesëm<br />\n3 copë - porcion i madh<br />\n4 copë - kujdes se garant ka me t’tepru pak...</p>\n<p>Nëse dëshironi ta merrni me tepsi, ju lutem shtoni 20 copë për secilën tepsi që e dëshironi. Pastaj e CEKNI tek detajet e porosisë nëse dëshironi ti paketoni në ndonjë mënyrë të caktuar. Përndryshe ne i paketojmë në paketimet tona transportuese.</p>\n<p>Ju lutem keni parasysh se për ta marrë me tepsi duhet të lini avans prej 10€ për secilën tepsi që e merrni. Avansi kthehet kur e ktheni tepsinë.</p>\n",
    short_description: "",
    sku: "00038",
    price: "0.6",
    regular_price: "",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 1,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 49,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "1",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 24,
        name: "Drekë",
        slug: "dreke",
      },
    ],
    tags: [],
    images: [
      {
        id: 81,
        date_created: "2023-02-11T15:24:13",
        date_created_gmt: "2023-02-11T14:24:13",
        date_modified: "2023-02-11T15:24:13",
        date_modified_gmt: "2023-02-11T14:24:13",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/krelane-e-kollomojt_1.jpg",
        name: "krelane-e-kollomojt_1.jpg",
        alt: "",
      },
      {
        id: 82,
        date_created: "2023-02-11T15:24:15",
        date_created_gmt: "2023-02-11T14:24:15",
        date_modified: "2023-02-11T15:24:15",
        date_modified_gmt: "2023-02-11T14:24:15",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/krelane-e-kollomojt.jpg",
        name: "krelane-e-kollomojt.jpg",
        alt: "",
      },
    ],
    attributes: [
      {
        id: 3,
        name: "Sasia",
        position: 0,
        visible: true,
        variation: true,
        options: ["copë", "tepsi e madhe"],
      },
    ],
    default_attributes: [],
    variations: [110, 124],
    grouped_products: [],
    menu_order: 0,
    price_html:
      '<span class="woocommerce-Price-amount amount"><bdi>0.60<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span> &ndash; <span class="woocommerce-Price-amount amount"><bdi>12.00<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [41, 36, 149, 45, 154],
    meta_data: [],
    stock_status: "instock",
    has_options: true,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/35",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 34,
    name: "Jogurt 1L",
    slug: "jogurt-1l",
    permalink: "https://www.cms.furralumi.com/product/jogurt-1l/",
    date_created: "2023-02-11T15:23:37",
    date_created_gmt: "2023-02-11T14:23:37",
    date_modified: "2023-02-11T15:24:13",
    date_modified_gmt: "2023-02-11T14:24:13",
    type: "simple",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "00014",
    price: "1.0",
    regular_price: "1.0",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 0,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 31,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 21,
        name: "Pije",
        slug: "pije",
      },
    ],
    tags: [],
    images: [
      {
        id: 80,
        date_created: "2023-02-11T15:24:11",
        date_created_gmt: "2023-02-11T14:24:11",
        date_modified: "2023-02-11T15:24:11",
        date_modified_gmt: "2023-02-11T14:24:11",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/Jogurt_1L.jpg",
        name: "Jogurt_1L.jpg",
        alt: "",
      },
    ],
    attributes: [],
    default_attributes: [],
    variations: [],
    grouped_products: [],
    menu_order: 0,
    price_html: '<span class="woocommerce-Price-amount amount"><bdi>1.00<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [17, 33, 18, 27, 32],
    meta_data: [],
    stock_status: "instock",
    has_options: false,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/34",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 33,
    name: "Jogurt",
    slug: "jogurt",
    permalink: "https://www.cms.furralumi.com/product/jogurt/",
    date_created: "2023-02-11T15:23:37",
    date_created_gmt: "2023-02-11T14:23:37",
    date_modified: "2023-02-11T15:24:11",
    date_modified_gmt: "2023-02-11T14:24:11",
    type: "simple",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "00043",
    price: "0.35",
    regular_price: "0.35",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 0,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 52,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: true,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 21,
        name: "Pije",
        slug: "pije",
      },
    ],
    tags: [],
    images: [
      {
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/04/Fotot-do-te-shtohen-se-shpejti_2.jpg",
        name: "default",
        alt: "",
      },
    ],
    attributes: [],
    default_attributes: [],
    variations: [],
    grouped_products: [],
    menu_order: 0,
    price_html: '<span class="woocommerce-Price-amount amount"><bdi>0.35<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [22, 31, 29, 18, 27],
    meta_data: [],
    stock_status: "instock",
    has_options: false,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/33",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 36,
    name: "Leçenik",
    slug: "lecenik",
    permalink: "https://www.cms.furralumi.com/product/lecenik/",
    date_created: "2023-02-11T15:23:37",
    date_created_gmt: "2023-02-11T14:23:37",
    date_modified: "2023-02-11T15:25:15",
    date_modified_gmt: "2023-02-11T14:25:15",
    type: "variable",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description:
      "<p>Ne i shesim produktet tona në mënyrë që ju të bleni aq sa i konsumoni. Prandaj Leçeniku llogaritet me copë. Kështu ju mund të përzgjedhni edhe produkte tjera të cilat ua kompletojnë një drekë tradicionale me shumëllojshmëri.</p>\n<p>Tepsia e madhe përmban 24 pjesë.</p>\n<p>Shujtat llogariten si mëposhtë:</p>\n<p>1 copë - porcion i vogël<br />\n2 copë - porcion i mesëm<br />\n3 copë - porcion i madh<br />\n4 copë - kujdes se garant ka me t’tepru pak...</p>\n<p>Nëse dëshironi ta merrni me tepsi, ju lutem shtoni 24 copë për secilën tepsi që e dëshironi. Pastaj e CEKNI tek detajet e porosisë nëse dëshironi ti paketoni në ndonjë mënyrë të caktuar. Përndryshe ne i paketojmë në paketimet tona transportuese.</p>\n<p>Ju lutem keni parasysh se për ta marrë me tepsi duhet të lini avans prej 10€ për secilën tepsi që e merrni. Avansi kthehet kur e ktheni tepsinë.</p>\n",
    short_description: "",
    sku: "00021",
    price: "1.20",
    regular_price: "",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 0,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 50,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 24,
        name: "Drekë",
        slug: "dreke",
      },
    ],
    tags: [],
    images: [
      {
        id: 83,
        date_created: "2023-02-11T15:24:16",
        date_created_gmt: "2023-02-11T14:24:16",
        date_modified: "2023-02-11T15:24:16",
        date_modified_gmt: "2023-02-11T14:24:16",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/lecenik.jpg",
        name: "lecenik.jpg",
        alt: "",
      },
      {
        id: 84,
        date_created: "2023-02-11T15:24:18",
        date_created_gmt: "2023-02-11T14:24:18",
        date_modified: "2023-02-11T15:24:18",
        date_modified_gmt: "2023-02-11T14:24:18",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/lecenik_1.jpg",
        name: "lecenik_1.jpg",
        alt: "",
      },
    ],
    attributes: [
      {
        id: 3,
        name: "Sasia",
        position: 0,
        visible: true,
        variation: true,
        options: ["copë", "tepsi e madhe"],
      },
    ],
    default_attributes: [],
    variations: [125, 126],
    grouped_products: [],
    menu_order: 0,
    price_html:
      '<span class="woocommerce-Price-amount amount"><bdi>1.20<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span> &ndash; <span class="woocommerce-Price-amount amount"><bdi>28.80<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [149, 41, 248, 40, 35],
    meta_data: [],
    stock_status: "instock",
    has_options: true,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/36",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 27,
    name: "Fanta",
    slug: "fanta",
    permalink: "https://www.cms.furralumi.com/product/fanta/",
    date_created: "2023-02-11T15:23:37",
    date_created_gmt: "2023-02-11T14:23:37",
    date_modified: "2023-02-11T15:24:00",
    date_modified_gmt: "2023-02-11T14:24:00",
    type: "simple",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "00054",
    price: "1",
    regular_price: "1",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 0,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: false,
    stock_quantity: null,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 21,
        name: "Pije",
        slug: "pije",
      },
    ],
    tags: [],
    images: [
      {
        id: 70,
        date_created: "2023-02-11T15:24:00",
        date_created_gmt: "2023-02-11T14:24:00",
        date_modified: "2023-02-11T15:24:00",
        date_modified_gmt: "2023-02-11T14:24:00",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/Fanta.jpg",
        name: "Fanta.jpg",
        alt: "",
      },
    ],
    attributes: [],
    default_attributes: [],
    variations: [],
    grouped_products: [],
    menu_order: 0,
    price_html: '<span class="woocommerce-Price-amount amount"><bdi>1.00<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [17, 31, 29, 22, 32],
    meta_data: [],
    stock_status: "instock",
    has_options: false,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/27",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 28,
    name: "Fli me kilogram",
    slug: "fli-me-kilogram",
    permalink: "https://www.cms.furralumi.com/product/fli-me-kilogram/",
    date_created: "2023-02-11T15:23:37",
    date_created_gmt: "2023-02-11T14:23:37",
    date_modified: "2023-03-05T12:22:39",
    date_modified_gmt: "2023-03-05T11:22:39",
    type: "variable",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description:
      "<p>Ne i shesim produktet tona në mënyrë që ju të bleni aq sa i konsumoni. Prandaj flia llogaritet në bazë të peshës. Kështu ju mund të përzgjedhni edhe produkte tjera të cilat ua kompletojnë një drekë tradicionale me shumëllojshmëri.</p>\n<p>Tepsia e madhe peshon 6kg dhe shërben 16 persona<br />\n3 kg - 8 persona<br />\n2 kg - 5 persona<br />\n1.6 kg - 4 persona<br />\n1.2 kg - 3 persona<br />\n0.8 kg - 2 persona<br />\n0.4 kg - 1 person</p>\n<p>Këto më lartë janë llogaritur si porcione të mëdha.</p>\n<p>250gr - porcion i vogël<br />\n325gr - porcion i mesëm<br />\n375gr - porcion i madh<br />\n500gr - kujdes se garant ka me t’tepru pak...</p>\n<p>Nëse dëshironi ta merrni flinë me tepsi, ju lutem shtoni 6kg për secilën tepsi që e dëshironi. Pastaj e CEKNI tek detajet e porosisë nëse dëshironi ti preni në ndonjë mënyrë të caktuar. Përndryshe ne i paketojmë në paketimet tona transportuese.</p>\n<p>Ju lutem keni parasysh se për ta marrë me tepsi duhet të lini avans prej 10€ për secilën tepsi që e merrni. Avansi kthehet kur e ktheni tepsinë.</p>\n",
    short_description: "",
    sku: "00018",
    price: "5.5",
    regular_price: "",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 3,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 110,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "1",
    dimensions: {
      length: "",
      width: "",
      height: "0.1",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 24,
        name: "Drekë",
        slug: "dreke",
      },
    ],
    tags: [],
    images: [
      {
        id: 71,
        date_created: "2023-02-11T15:24:00",
        date_created_gmt: "2023-02-11T14:24:00",
        date_modified: "2023-02-11T15:24:00",
        date_modified_gmt: "2023-02-11T14:24:00",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/fli_7.jpg",
        name: "fli_7.jpg",
        alt: "",
      },
      {
        id: 72,
        date_created: "2023-02-11T15:24:02",
        date_created_gmt: "2023-02-11T14:24:02",
        date_modified: "2023-02-11T15:24:02",
        date_modified_gmt: "2023-02-11T14:24:02",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/fli_3.jpg",
        name: "fli_3.jpg",
        alt: "",
      },
      {
        id: 73,
        date_created: "2023-02-11T15:24:04",
        date_created_gmt: "2023-02-11T14:24:04",
        date_modified: "2023-02-11T15:24:04",
        date_modified_gmt: "2023-02-11T14:24:04",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/fli_1.jpg",
        name: "fli_1.jpg",
        alt: "",
      },
      {
        id: 74,
        date_created: "2023-02-11T15:24:05",
        date_created_gmt: "2023-02-11T14:24:05",
        date_modified: "2023-02-11T15:24:05",
        date_modified_gmt: "2023-02-11T14:24:05",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/fli_5.jpg",
        name: "fli_5.jpg",
        alt: "",
      },
      {
        id: 75,
        date_created: "2023-02-11T15:24:07",
        date_created_gmt: "2023-02-11T14:24:07",
        date_modified: "2023-02-11T15:24:07",
        date_modified_gmt: "2023-02-11T14:24:07",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/fli.jpg",
        name: "fli.jpg",
        alt: "",
      },
    ],
    attributes: [
      {
        id: 3,
        name: "Sasia",
        position: 0,
        visible: true,
        variation: true,
        options: ["me kilogram", "tepsi e madhe"],
      },
    ],
    default_attributes: [],
    variations: [131, 138],
    grouped_products: [],
    menu_order: 0,
    price_html:
      '<span class="woocommerce-Price-amount amount"><bdi>5.50<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span> &ndash; <span class="woocommerce-Price-amount amount"><bdi>33.00<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [149, 40, 41, 36, 35],
    meta_data: [],
    stock_status: "instock",
    has_options: true,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/28",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 29,
    name: "Frutomania, me Mjedër",
    slug: "frutomania-me-mjeder",
    permalink: "https://www.cms.furralumi.com/product/frutomania-me-mjeder/",
    date_created: "2023-02-11T15:23:37",
    date_created_gmt: "2023-02-11T14:23:37",
    date_modified: "2023-03-29T14:50:52",
    date_modified_gmt: "2023-03-29T13:50:52",
    type: "simple",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "00055",
    price: "1.0",
    regular_price: "1.0",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 1,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 76,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 21,
        name: "Pije",
        slug: "pije",
      },
    ],
    tags: [],
    images: [
      {
        id: 76,
        date_created: "2023-02-11T15:24:09",
        date_created_gmt: "2023-02-11T14:24:09",
        date_modified: "2023-02-11T15:24:09",
        date_modified_gmt: "2023-02-11T14:24:09",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/Frutomania_Mjeder.jpg",
        name: "Frutomania_Mjeder.jpg",
        alt: "",
      },
    ],
    attributes: [],
    default_attributes: [],
    variations: [],
    grouped_products: [],
    menu_order: 0,
    price_html: '<span class="woocommerce-Price-amount amount"><bdi>1.00<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [18, 32, 17, 22, 33],
    meta_data: [],
    stock_status: "instock",
    has_options: false,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/29",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 30,
    name: "Frutomania, me Mollë",
    slug: "frutomania-me-molle",
    permalink: "https://www.cms.furralumi.com/product/frutomania-me-molle/",
    date_created: "2023-02-11T15:23:37",
    date_created_gmt: "2023-02-11T14:23:37",
    date_modified: "2023-02-11T15:24:10",
    date_modified_gmt: "2023-02-11T14:24:10",
    type: "simple",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "00057",
    price: "1.0",
    regular_price: "1.0",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 0,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 96,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "1",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 21,
        name: "Pije",
        slug: "pije",
      },
    ],
    tags: [],
    images: [
      {
        id: 77,
        date_created: "2023-02-11T15:24:09",
        date_created_gmt: "2023-02-11T14:24:09",
        date_modified: "2023-02-11T15:24:09",
        date_modified_gmt: "2023-02-11T14:24:09",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/Frutomania_Molle.jpg",
        name: "Frutomania_Molle.jpg",
        alt: "",
      },
    ],
    attributes: [],
    default_attributes: [],
    variations: [],
    grouped_products: [],
    menu_order: 0,
    price_html: '<span class="woocommerce-Price-amount amount"><bdi>1.00<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [33, 18, 31, 17, 34],
    meta_data: [],
    stock_status: "instock",
    has_options: false,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/30",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 31,
    name: "Frutomania, me Mollë & Karrotë",
    slug: "frutomania-me-molle-karrote",
    permalink: "https://www.cms.furralumi.com/product/frutomania-me-molle-karrote/",
    date_created: "2023-02-11T15:23:37",
    date_created_gmt: "2023-02-11T14:23:37",
    date_modified: "2023-02-13T17:13:23",
    date_modified_gmt: "2023-02-13T16:13:23",
    type: "simple",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "00058",
    price: "1.0",
    regular_price: "1.0",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 1,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 95,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 21,
        name: "Pije",
        slug: "pije",
      },
    ],
    tags: [],
    images: [
      {
        id: 78,
        date_created: "2023-02-11T15:24:10",
        date_created_gmt: "2023-02-11T14:24:10",
        date_modified: "2023-02-11T15:24:10",
        date_modified_gmt: "2023-02-11T14:24:10",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/Frutomania_MolleKarrote.jpg",
        name: "Frutomania_MolleKarrote.jpg",
        alt: "",
      },
    ],
    attributes: [],
    default_attributes: [],
    variations: [],
    grouped_products: [],
    menu_order: 0,
    price_html: '<span class="woocommerce-Price-amount amount"><bdi>1.00<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [27, 32, 29, 33, 18],
    meta_data: [],
    stock_status: "instock",
    has_options: false,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/31",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 32,
    name: "Ice Tea",
    slug: "ice-tea",
    permalink: "https://www.cms.furralumi.com/product/ice-tea/",
    date_created: "2023-02-11T15:23:37",
    date_created_gmt: "2023-02-11T14:23:37",
    date_modified: "2023-03-05T01:31:43",
    date_modified_gmt: "2023-03-05T00:31:43",
    type: "simple",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "00056",
    price: "1",
    regular_price: "1",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 2,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 95,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 21,
        name: "Pije",
        slug: "pije",
      },
    ],
    tags: [],
    images: [
      {
        id: 79,
        date_created: "2023-02-11T15:24:11",
        date_created_gmt: "2023-02-11T14:24:11",
        date_modified: "2023-02-11T15:24:11",
        date_modified_gmt: "2023-02-11T14:24:11",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/Sola_IceTea.jpg",
        name: "Sola_IceTea.jpg",
        alt: "",
      },
    ],
    attributes: [],
    default_attributes: [],
    variations: [],
    grouped_products: [],
    menu_order: 0,
    price_html: '<span class="woocommerce-Price-amount amount"><bdi>1.00<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [34, 30, 17, 31, 33],
    meta_data: [],
    stock_status: "instock",
    has_options: false,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/32",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 18,
    name: "Ajran 1L",
    slug: "ajran-1l",
    permalink: "https://www.cms.furralumi.com/product/ajran-1l/",
    date_created: "2023-02-11T15:23:36",
    date_created_gmt: "2023-02-11T14:23:36",
    date_modified: "2023-03-05T00:37:50",
    date_modified_gmt: "2023-03-04T23:37:50",
    type: "simple",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "00013",
    price: "1.0",
    regular_price: "1.0",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 6,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 41,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 21,
        name: "Pije",
        slug: "pije",
      },
    ],
    tags: [],
    images: [
      {
        id: 47,
        date_created: "2023-02-11T15:23:38",
        date_created_gmt: "2023-02-11T14:23:38",
        date_modified: "2023-02-11T15:23:38",
        date_modified_gmt: "2023-02-11T14:23:38",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/Ajran_1L.jpg",
        name: "Ajran_1L.jpg",
        alt: "",
      },
    ],
    attributes: [],
    default_attributes: [],
    variations: [],
    grouped_products: [],
    menu_order: 0,
    price_html: '<span class="woocommerce-Price-amount amount"><bdi>1.00<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [32, 34, 33, 29, 22],
    meta_data: [],
    stock_status: "instock",
    has_options: false,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/18",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 19,
    name: "Bakllavë",
    slug: "bakllave",
    permalink: "https://www.cms.furralumi.com/product/bakllave/",
    date_created: "2023-02-11T15:23:36",
    date_created_gmt: "2023-02-11T14:23:36",
    date_modified: "2023-03-30T17:07:49",
    date_modified_gmt: "2023-03-30T16:07:49",
    type: "variable",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "00044",
    price: "1.20",
    regular_price: "",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 5,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 94,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "1",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 22,
        name: "Ëmbëlsirat individuale",
        slug: "embelsirat-individuale",
      },
    ],
    tags: [],
    images: [
      {
        id: 48,
        date_created: "2023-02-11T15:23:40",
        date_created_gmt: "2023-02-11T14:23:40",
        date_modified: "2023-02-11T15:23:40",
        date_modified_gmt: "2023-02-11T14:23:40",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/bakllava.jpg",
        name: "bakllava.jpg",
        alt: "",
      },
      {
        id: 49,
        date_created: "2023-02-11T15:23:41",
        date_created_gmt: "2023-02-11T14:23:41",
        date_modified: "2023-02-11T15:23:41",
        date_modified_gmt: "2023-02-11T14:23:41",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/bakllava_1.jpg",
        name: "bakllava_1.jpg",
        alt: "",
      },
    ],
    attributes: [
      {
        id: 3,
        name: "Sasia",
        position: 0,
        visible: true,
        variation: true,
        options: ["copë", "tepsi e madhe"],
      },
    ],
    default_attributes: [],
    variations: [127, 128],
    grouped_products: [],
    menu_order: 0,
    price_html: '<span class="woocommerce-Price-amount amount"><bdi>1.20<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [46, 26, 25, 23, 44],
    meta_data: [],
    stock_status: "instock",
    has_options: true,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/19",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 20,
    name: "Biskota Qese",
    slug: "biskota-qese",
    permalink: "https://www.cms.furralumi.com/product/biskota-qese/",
    date_created: "2023-02-11T15:23:36",
    date_created_gmt: "2023-02-11T14:23:36",
    date_modified: "2023-02-11T15:23:46",
    date_modified_gmt: "2023-02-11T14:23:46",
    type: "simple",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "00048",
    price: "2.15",
    regular_price: "2.15",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 0,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: false,
    stock_quantity: null,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 22,
        name: "Ëmbëlsirat individuale",
        slug: "embelsirat-individuale",
      },
    ],
    tags: [
      {
        id: 23,
        name: "Me paketim",
        slug: "me-paketim",
      },
    ],
    images: [
      {
        id: 50,
        date_created: "2023-02-11T15:23:43",
        date_created_gmt: "2023-02-11T14:23:43",
        date_modified: "2023-02-11T15:23:43",
        date_modified_gmt: "2023-02-11T14:23:43",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/biskote-me-cokollate-paketim.jpg",
        name: "biskote-me-cokollate-paketim.jpg",
        alt: "",
      },
      {
        id: 51,
        date_created: "2023-02-11T15:23:45",
        date_created_gmt: "2023-02-11T14:23:45",
        date_modified: "2023-02-11T15:23:45",
        date_modified_gmt: "2023-02-11T14:23:45",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/biskota-me-cokollate-paketim_1.jpg",
        name: "biskota-me-cokollate-paketim_1.jpg",
        alt: "",
      },
    ],
    attributes: [],
    default_attributes: [],
    variations: [],
    grouped_products: [],
    menu_order: 0,
    price_html: '<span class="woocommerce-Price-amount amount"><bdi>2.15<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [44, 37, 26, 25, 39],
    meta_data: [],
    stock_status: "instock",
    has_options: false,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/20",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 21,
    name: "Cantucci",
    slug: "cantucci",
    permalink: "https://www.cms.furralumi.com/product/cantucci/",
    date_created: "2023-02-11T15:23:36",
    date_created_gmt: "2023-02-11T14:23:36",
    date_modified: "2023-02-11T15:23:46",
    date_modified_gmt: "2023-02-11T14:23:46",
    type: "simple",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "00049",
    price: "2.3",
    regular_price: "2.3",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 0,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 50,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 22,
        name: "Ëmbëlsirat individuale",
        slug: "embelsirat-individuale",
      },
    ],
    tags: [
      {
        id: 23,
        name: "Me paketim",
        slug: "me-paketim",
      },
    ],
    images: [
      {
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/04/Fotot-do-te-shtohen-se-shpejti_2.jpg",
        name: "default",
        alt: "",
      },
    ],
    attributes: [],
    default_attributes: [],
    variations: [],
    grouped_products: [],
    menu_order: 0,
    price_html: '<span class="woocommerce-Price-amount amount"><bdi>2.30<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [23, 44, 19, 43, 20],
    meta_data: [],
    stock_status: "instock",
    has_options: false,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/21",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 22,
    name: "Coca Cola",
    slug: "coca-cola",
    permalink: "https://www.cms.furralumi.com/product/coca-cola/",
    date_created: "2023-02-11T15:23:36",
    date_created_gmt: "2023-02-11T14:23:36",
    date_modified: "2023-02-12T14:11:04",
    date_modified_gmt: "2023-02-12T13:11:04",
    type: "simple",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "00053",
    price: "1",
    regular_price: "1",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 1,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 193,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 21,
        name: "Pije",
        slug: "pije",
      },
    ],
    tags: [],
    images: [
      {
        id: 52,
        date_created: "2023-02-11T15:23:46",
        date_created_gmt: "2023-02-11T14:23:46",
        date_modified: "2023-02-11T15:23:46",
        date_modified_gmt: "2023-02-11T14:23:46",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/CC.jpg",
        name: "CC.jpg",
        alt: "",
      },
    ],
    attributes: [],
    default_attributes: [],
    variations: [],
    grouped_products: [],
    menu_order: 0,
    price_html: '<span class="woocommerce-Price-amount amount"><bdi>1.00<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [18, 27, 29, 31, 33],
    meta_data: [],
    stock_status: "instock",
    has_options: false,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/22",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 23,
    name: "Çokollatë dhuratë",
    slug: "cokollate-dhurate",
    permalink: "https://www.cms.furralumi.com/product/cokollate-dhurate/",
    date_created: "2023-02-11T15:23:36",
    date_created_gmt: "2023-02-11T14:23:36",
    date_modified: "2023-02-11T15:23:50",
    date_modified_gmt: "2023-02-11T14:23:50",
    type: "simple",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "00051",
    price: "8.15",
    regular_price: "8.15",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 0,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 9,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 22,
        name: "Ëmbëlsirat individuale",
        slug: "embelsirat-individuale",
      },
    ],
    tags: [
      {
        id: 23,
        name: "Me paketim",
        slug: "me-paketim",
      },
    ],
    images: [
      {
        id: 53,
        date_created: "2023-02-11T15:23:47",
        date_created_gmt: "2023-02-11T14:23:47",
        date_modified: "2023-02-11T15:23:47",
        date_modified_gmt: "2023-02-11T14:23:47",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/cokollate-bombonjere_1.jpg",
        name: "cokollate-bombonjere_1.jpg",
        alt: "",
      },
      {
        id: 54,
        date_created: "2023-02-11T15:23:48",
        date_created_gmt: "2023-02-11T14:23:48",
        date_modified: "2023-02-11T15:23:48",
        date_modified_gmt: "2023-02-11T14:23:48",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/cokollate-bombonjere_2.jpg",
        name: "cokollate-bombonjere_2.jpg",
        alt: "",
      },
    ],
    attributes: [],
    default_attributes: [],
    variations: [],
    grouped_products: [],
    menu_order: 0,
    price_html: '<span class="woocommerce-Price-amount amount"><bdi>8.15<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [25, 24, 42, 19, 43],
    meta_data: [],
    stock_status: "instock",
    has_options: false,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/23",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 24,
    name: "Çokollatë paketim",
    slug: "cokollate-paketim",
    permalink: "https://www.cms.furralumi.com/product/cokollate-paketim/",
    date_created: "2023-02-11T15:23:36",
    date_created_gmt: "2023-02-11T14:23:36",
    date_modified: "2023-02-11T15:23:50",
    date_modified_gmt: "2023-02-11T14:23:50",
    type: "simple",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "00052",
    price: "9.60",
    regular_price: "9.60",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 1,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: false,
    stock_quantity: null,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 22,
        name: "Ëmbëlsirat individuale",
        slug: "embelsirat-individuale",
      },
    ],
    tags: [
      {
        id: 23,
        name: "Me paketim",
        slug: "me-paketim",
      },
    ],
    images: [
      {
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/04/Fotot-do-te-shtohen-se-shpejti_2.jpg",
        name: "default",
        alt: "",
      },
    ],
    attributes: [],
    default_attributes: [],
    variations: [],
    grouped_products: [],
    menu_order: 0,
    price_html: '<span class="woocommerce-Price-amount amount"><bdi>9.60<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [46, 44, 42, 43, 23],
    meta_data: [],
    stock_status: "instock",
    has_options: false,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/24",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 25,
    name: "Cremeschnitte",
    slug: "cremeschnitte",
    permalink: "https://www.cms.furralumi.com/product/cremeschnitte/",
    date_created: "2023-02-11T15:23:36",
    date_created_gmt: "2023-02-11T14:23:36",
    date_modified: "2023-02-11T15:25:15",
    date_modified_gmt: "2023-02-11T14:25:15",
    type: "variable",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "00011",
    price: "1.4",
    regular_price: "",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 0,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 50,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 22,
        name: "Ëmbëlsirat individuale",
        slug: "embelsirat-individuale",
      },
    ],
    tags: [],
    images: [
      {
        id: 55,
        date_created: "2023-02-11T15:23:50",
        date_created_gmt: "2023-02-11T14:23:50",
        date_modified: "2023-02-11T15:23:50",
        date_modified_gmt: "2023-02-11T14:23:50",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/cremshnite.jpg",
        name: "cremshnite.jpg",
        alt: "",
      },
      {
        id: 56,
        date_created: "2023-02-11T15:23:52",
        date_created_gmt: "2023-02-11T14:23:52",
        date_modified: "2023-02-11T15:23:52",
        date_modified_gmt: "2023-02-11T14:23:52",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/cremshnite_1.jpg",
        name: "cremshnite_1.jpg",
        alt: "",
      },
      {
        id: 57,
        date_created: "2023-02-11T15:23:53",
        date_created_gmt: "2023-02-11T14:23:53",
        date_modified: "2023-02-11T15:23:53",
        date_modified_gmt: "2023-02-11T14:23:53",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/cremshnite_2.jpg",
        name: "cremshnite_2.jpg",
        alt: "",
      },
      {
        id: 58,
        date_created: "2023-02-11T15:23:55",
        date_created_gmt: "2023-02-11T14:23:55",
        date_modified: "2023-02-11T15:23:55",
        date_modified_gmt: "2023-02-11T14:23:55",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/cremshnite_3.jpg",
        name: "cremshnite_3.jpg",
        alt: "",
      },
    ],
    attributes: [
      {
        id: 3,
        name: "Sasia",
        position: 0,
        visible: true,
        variation: true,
        options: ["copë"],
      },
    ],
    default_attributes: [
      {
        id: 3,
        name: "Sasia",
        option: "cope",
      },
    ],
    variations: [129],
    grouped_products: [],
    menu_order: 0,
    price_html: '<span class="woocommerce-Price-amount amount"><bdi>1.40<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [24, 46, 43, 44, 26],
    meta_data: [],
    stock_status: "instock",
    has_options: true,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/25",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 26,
    name: "Ekler",
    slug: "ekler",
    permalink: "https://www.cms.furralumi.com/product/ekler/",
    date_created: "2023-02-11T15:23:36",
    date_created_gmt: "2023-02-11T14:23:36",
    date_modified: "2023-02-11T15:25:15",
    date_modified_gmt: "2023-02-11T14:25:15",
    type: "variable",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "00040",
    price: "1.05",
    regular_price: "",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 0,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 50,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 22,
        name: "Ëmbëlsirat individuale",
        slug: "embelsirat-individuale",
      },
    ],
    tags: [],
    images: [
      {
        id: 59,
        date_created: "2023-02-11T15:23:56",
        date_created_gmt: "2023-02-11T14:23:56",
        date_modified: "2023-02-11T15:23:56",
        date_modified_gmt: "2023-02-11T14:23:56",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/ekler.jpg",
        name: "ekler.jpg",
        alt: "",
      },
    ],
    attributes: [
      {
        id: 3,
        name: "Sasia",
        position: 0,
        visible: true,
        variation: true,
        options: ["copë"],
      },
    ],
    default_attributes: [
      {
        id: 3,
        name: "Sasia",
        option: "cope",
      },
    ],
    variations: [130],
    grouped_products: [],
    menu_order: 0,
    price_html: '<span class="woocommerce-Price-amount amount"><bdi>1.05<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [46, 44, 25, 39, 23],
    meta_data: [],
    stock_status: "instock",
    has_options: true,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/26",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 17,
    name: "Ajran",
    slug: "ajran",
    permalink: "https://www.cms.furralumi.com/product/ajran/",
    date_created: "2023-02-11T15:23:35",
    date_created_gmt: "2023-02-11T14:23:35",
    date_modified: "2023-02-11T15:23:38",
    date_modified_gmt: "2023-02-11T14:23:38",
    type: "simple",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "",
    short_description: "",
    sku: "00036",
    price: "0.35",
    regular_price: "0.35",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 0,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: 0,
    download_expiry: 0,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 50,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "0",
    dimensions: {
      length: "",
      width: "",
      height: "",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: false,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 21,
        name: "Pije",
        slug: "pije",
      },
    ],
    tags: [],
    images: [
      {
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/04/Fotot-do-te-shtohen-se-shpejti_2.jpg",
        name: "default",
        alt: "",
      },
    ],
    attributes: [],
    default_attributes: [],
    variations: [],
    grouped_products: [],
    menu_order: 0,
    price_html: '<span class="woocommerce-Price-amount amount"><bdi>0.35<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [33, 27, 30, 32, 31],
    meta_data: [],
    stock_status: "instock",
    has_options: false,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/17",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
  {
    id: 14,
    name: "Test product",
    slug: "test-product",
    permalink: "https://www.cms.furralumi.com/product/test-product/",
    date_created: "2023-02-11T13:49:35",
    date_created_gmt: "2023-02-11T12:49:35",
    date_modified: "2023-03-28T17:31:41",
    date_modified_gmt: "2023-03-28T16:31:41",
    type: "simple",
    status: "publish",
    featured: false,
    catalog_visibility: "visible",
    description: "<p>Test product description</p>\n",
    short_description: "<p>Test short des.</p>\n",
    sku: "test",
    price: "0.24",
    regular_price: "0.24",
    sale_price: "",
    date_on_sale_from: null,
    date_on_sale_from_gmt: null,
    date_on_sale_to: null,
    date_on_sale_to_gmt: null,
    on_sale: false,
    purchasable: true,
    total_sales: 79,
    virtual: false,
    downloadable: false,
    downloads: [],
    download_limit: -1,
    download_expiry: -1,
    external_url: "",
    button_text: "",
    tax_status: "taxable",
    tax_class: "",
    manage_stock: true,
    stock_quantity: 922,
    backorders: "no",
    backorders_allowed: false,
    backordered: false,
    low_stock_amount: null,
    sold_individually: false,
    weight: "",
    dimensions: {
      length: "",
      width: "",
      height: "",
    },
    shipping_required: true,
    shipping_taxable: true,
    shipping_class: "",
    shipping_class_id: 0,
    reviews_allowed: true,
    average_rating: "0.00",
    rating_count: 0,
    upsell_ids: [],
    cross_sell_ids: [],
    parent_id: 0,
    purchase_note: "",
    categories: [
      {
        id: 16,
        name: "Uncategorized",
        slug: "uncategorized",
      },
    ],
    tags: [
      {
        id: 20,
        name: "first",
        slug: "first",
      },
      {
        id: 19,
        name: "test",
        slug: "test",
      },
    ],
    images: [
      {
        id: 15,
        date_created: "2023-02-11T15:15:49",
        date_created_gmt: "2023-02-11T14:15:49",
        date_modified: "2023-02-11T15:15:49",
        date_modified_gmt: "2023-02-11T14:15:49",
        src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/mantia-scaled-1.jpg",
        name: "mantia-scaled",
        alt: "",
      },
    ],
    attributes: [],
    default_attributes: [],
    variations: [],
    grouped_products: [],
    menu_order: 0,
    price_html: '<span class="woocommerce-Price-amount amount"><bdi>0.24<span class="woocommerce-Price-currencySymbol">&euro;</span></bdi></span>',
    related_ids: [],
    meta_data: [],
    stock_status: "instock",
    has_options: false,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/14",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products",
        },
      ],
    },
  },
];
export default allProductsData;
