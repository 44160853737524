import { NavLink } from "react-router-dom";
import allCategoriesData from "../data/allCategoriesData";

// console.log(allCategoriesData);

function CategoriesNavbar() {
  return (
    <>
      <h5 className="pb-2">Kategoritë</h5>
      <div className=" pb-5 px-0">
        <div className="row g-3 justify-content-center">
          {allCategoriesData.map((category) => {
            return (
              <div
                className=" col-6 col-sm-4 col-md text-center  "
                // style={{ backgroundImage: `url(${category.image.src})`, backgroundSize: "cover" }}
              >
                <NavLink
                  style={({ isActive }) => {
                    return isActive ? { opacity: "100%", backgroundColor: "#98694c", color: "#ffffff" } : { opacity: "100%", backgroundColor: "#ffffff", color: "#98694c" };
                  }}
                  to={category.slug === "products" ? "/products" : "/product/" + category.slug}
                  className=" btn container-fluid fw-bold shadow-sm row align-items-center"
                >
                  {/* <div className="p-1" style={{ width: "100%" }}> */}
                  {category.name}
                  {/* </div> */}
                </NavLink>
              </div>
            );
          })}
        </div>
        <div class="pt-5 input-group">
          <input type="text" class="form-control" placeholder="Kërko produktet..." />

          <span class="input-group-text bg-white">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
          </span>
        </div>
      </div>
    </>
  );
}

export default CategoriesNavbar;
