import { React } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import CategoriesNavbar from "../components/categoriesNavbar";
import allProductsData from "../data/allProductsData";
import allCategoriesData from "../data/allCategoriesData";

const bannerLokacionet = "https://www.cms.furralumi.com/wp-content/uploads/2023/03/FurraLumi_Wide_Lokacjonet_1-scaled.jpg";
const defaultImg = "https://furralumi.com/wp-content/uploads/2022/05/Fotot-do-te-shtohen-se-shpejti_2.jpg";

console.log("Categories:", allCategoriesData);

function ProductCategoryV2() {
  const { slug } = useParams();
  const currentCategory = allCategoriesData.find((item) => item.slug === slug);
  console.log("currentCategory:", currentCategory);
  return (
    <>
      <div className="container">
        <div className="rounded shadow-sm" style={{ backgroundImage: `url(${currentCategory.image.src})`, backgroundSize: "cover", backgroundPositionY: "50%" }}>
          <h4 className="rounded px-4 py-5 bg-primary shadow-sm text-white fw-bold text-center text-lg-start" style={{ "--bs-bg-opacity": 0.75 }}>
            {currentCategory.name}
          </h4>
        </div>
        <div className="row ">
          <div className="col-12 col-lg-3">
            <div className="row p-2 pt-2 mt-3 mb-4 bg-white shadow-sm rounded mx-0 sticky-top ">
              <h4 className="ps-1 ">Kategoritë</h4>
              <div className=" p-0 d-flex flex-wrap flex-row flex-lg-column overflow-auto ">
                {/* <CategoriesNavbar /> */}
                {allCategoriesData.map((category) => {
                  return (
                    <NavLink
                      style={({ isActive }) => {
                        return isActive ? { opacity: "100%", backgroundColor: "#98694c", color: "#ffffff", border: "0" } : { opacity: "100%", backgroundColor: "#ffffff", color: "#98694c" };
                      }}
                      to={category.slug === "products" ? "/products" : "/product/" + category.slug}
                      className=" btn  text-start fw-bold row align-items-center m-0 mt-1 mb-1 me-2 border border-primary border-1 text-nowrap"
                    >
                      {/* <div className="p-1" style={{ width: "100%" }}> */}
                      {category.name}
                      {/* </div> */}
                    </NavLink>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-9">
            <div className="row pt-3 px-2">
              {allProductsData.map((product) => {
                if (product.status !== "private")
                  if (product.categories[0].slug === slug)
                    return (
                      <Link to={"/products/" + product.slug} className="text-dark text-decoration-none col-12 col-sm-6 col-md-4 px-1 mb-4">
                        <div className="p-2 shadow-sm rounded bg-white h-100">
                          {/* <div>
                            {product.images[0] ? (
                              <img alt=" " src={product.images[0].src} className="rounded" style={{ width: "100%" }}></img>
                            ) : (
                              <img alt=" " src={defaultImg} className="rounded" style={{ width: "100%" }}></img>
                            )}
                          </div> */}
                          <div
                            className="ratio ratio-4x3  rounded"
                            style={
                              product.images[0]
                                ? { "backgroundImage": `url(${product.images[0].src})`, "backgroundSize": "cover", "backgroundPosition": "center", "--bs-aspect-ratio": "65%" }
                                : { "backgroundImage": `url(${defaultImg})`, "backgroundSize": "cover", "backgroundPosition": "center", "--bs-aspect-ratio": "65%" }
                            }
                          />
                          {/* <p>{product.id}</p> */}
                          {/* <img alt=""  src={product.images[0]}></img> */}
                          {/* <img alt=""  src={defaultImg} style={{ width: "150px" }}></img> */}
                          <p className="mb-0 mt-2 fw-semibold">{product.name}</p>
                          <p className="p-0 m-0">Nga {(product.price * 1).toFixed(2)} €</p>
                        </div>
                      </Link>
                    );
              })}
            </div>
          </div>
        </div>
      </div>
      <br />
    </>
  );
}

export default ProductCategoryV2;
