function Profile() {
  return (
    <>
      <div className="container text-center my-5 py-5">
        <h2 style={{ color: "#98694c" }} className=" pb-2">
          Në zhvillim e sipër.
        </h2>
        <p>Së shpejti ju do të mund të krijoni dhe kyqeni tek Furra Lumi me llogarinë tuaj në mënyrën më të leht të mundshme!</p>
      </div>
    </>
  );
}

export default Profile;
