import React from "react";
import { Link } from "react-router-dom";
// const firstBanner = "https://www.cms.furralumi.com/wp-content/uploads/2023/04/furra-lumi-2-interior_1-scaled-1.webp";
// const firstBanner = "https://www.cms.furralumi.com/wp-content/uploads/2023/04/FL_DesktopBanner_2.jpg";
const firstBanner = "https://www.cms.furralumi.com/wp-content/uploads/2023/04/FL_MobileBanner_2.jpg";
const secondBanner = "https://www.cms.furralumi.com/wp-content/uploads/2023/04/FL_MobileBanner_Lokacioni_ZoomedOut.jpg";
const thirdBanner = "https://www.cms.furralumi.com/wp-content/uploads/2023/04/FL_MobileBanner_Embelsira_ZoomedOut_biggerFile_compressed.jpg";

function HomeBannerColumns() {
  return (
    <div className="container-xl pb-4">
      <div className="  rounded rounded-3">
        <div className="row ">
          <div className="col-12 col-sm-4  pb-3 pb-sm-0">
            <Link className="text-dark text-decoration-none  " to="/products">
              <div className="p-1 p-sm-2 h-100 rounded rounded-3 overflow-hidden bg-white shadow-sm   d-flex flex-column text-center">
                <div className="ratio ratio-4x3 rounded d-block d-sm-none" style={{ backgroundImage: `url(${firstBanner})`, backgroundSize: "cover", backgroundPosition: "top" }}></div>
                <div className="ratio ratio-1x1 rounded d-none d-sm-block" style={{ backgroundImage: `url(${firstBanner})`, backgroundSize: "cover", backgroundPosition: "top" }}></div>

                <p className="my-auto py-3">Tani mund të bëni porosi nëpërmjet web faqes</p>
                <button className="btn btn-primary mt-2">Porosit Online</button>
              </div>
            </Link>
          </div>
          <div className="col-6 col-sm-4 pe-2 pe-sm-2">
            <Link className="text-dark text-decoration-none text-center " to="/products">
              <div className="p-1 p-sm-2 h-100 rounded rounded-3 overflow-hidden bg-white shadow-sm d-flex flex-column">
                <div className="ratio ratio-1x1 rounded" style={{ backgroundImage: `url(${secondBanner})`, backgroundSize: "cover", backgroundPosition: "center" }}></div>
                <p className="py-2 my-auto d-none d-sm-block">Porositni që me gjetë drekën që e doni, kur e doni, t’nxehtë. </p>
                <p className="py-2 my-auto  d-block d-sm-none">Porosit paraprakisht </p>
                <button className="btn btn-primary mt-2 px-1 px-sm-2">Mos Prit N'rend!</button>
              </div>
            </Link>
          </div>
          <div className="col-6 col-sm-4 ps-2 ps-sm-2">
            <Link className="text-dark text-decoration-none  " to="/product/embelsirat-individuale">
              <div className="p-1 p-sm-2 h-100 rounded rounded-3 overflow-hidden bg-white shadow-sm d-flex flex-column text-center">
                <div className=" ratio ratio-1x1 rounded" style={{ backgroundImage: `url(${thirdBanner})`, backgroundSize: "cover", backgroundPosition: "center" }}></div>

                <p className="py-2 my-auto">Gjeni shijen tuaj të preferuar.</p>
                <button className="btn btn-primary mt-2">Ëmbëlsirat</button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeBannerColumns;
