import { useContext } from "react";
import { CartContext } from "../Cart/cartComponent";
// import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
// import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, NavLink } from "react-router-dom";
import { CartOffCanvas } from "../Cart/cartOffCanvas";

const expand = "lg";

function OffcanvasExample() {
  const { cartSize } = useContext(CartContext);
  return (
    <>
      <div className="p-0 m-0" style={{ height: "65px" }}>
        <div className="fixed-top mb-3 border-bottom" style={{ zIndex: "999" }}>
          <Navbar key={expand} bg="white" expand={expand} className=" py-2">
            <Container>
              <Link to="/" className="fs-4 fw-bold text-decoration-none text-primary">
                Furra Lumi
              </Link>
              <Navbar.Toggle className="border-0" aria-controls={`offcanvasNavbar-expand-${expand}`} />
              <Navbar.Offcanvas id={`offcanvasNavbar-expand-${expand}`} aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`} placement="end" style={{ maxWidth: "90%" }}>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    <Link to="/" className="fs-4 fw-bold text-decoration-none text-primary">
                      Furra Lumi
                    </Link>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1">
                    {/* <Link className="text-decoration-none p-2" to="/products"> */}
                    {/* <div className="pe-5">
                    <Link className="btn btn-outline-light text-primary border-0 ms-2 my-0 " to="/products">
                      Produktet
                    </Link>
                    <Link className="btn btn-outline-primary bg-transparent text-primary border-0 ms-2 pb-3" to="/locations">
                      Lokacionet
                    </Link>
                    <Link className="btn btn-outline-primary bg-light text-primary border-0 ms-2 pb-3" to="/about-us">
                      Për ne
                    </Link>
                    <NavLink
                      style={({ isActive }) => {
                        return isActive ? { opacity: "100%", backgroundColor: "#98694c", color: "#ffffff" } : { opacity: "100%", backgroundColor: "#fafafa", color: "#98694c" };
                      }}
                      to="/about-us"
                      className=" btn btn-outline-light ms-2"
                    >
                      Për ne
                    </NavLink>
                    <Link className="btn btn-outline-primary border-0 ms-2 pb-3" to="/faqs">
                      Pyetjet e shpeshta
                    </Link>
                  </div> */}
                    <NavLink className="btn btn-outline-light text-primary border-0 ms-2 " activeClassName="bg-dark" to="/products">
                      Produktet
                    </NavLink>
                    <NavLink className="btn btn-outline-light text-primary border-0 ms-2 " activeClassName="bg-dark" to="/locations">
                      Lokacionet
                    </NavLink>
                    <NavLink className="btn btn-outline-light text-primary border-0 ms-2 " activeClassName="bg-dark" to="/about-us">
                      Për ne
                    </NavLink>

                    <NavLink className="btn btn-outline-light text-primary border-0 ms-2 " activeClassName="bg-dark" to="/faqs">
                      Pyetjet e shpeshta
                    </NavLink>
                    <div className="d-flex">
                      <NavLink
                        style={({ isActive }) => {
                          return isActive ? { opacity: "100%" } : { opacity: "100%" };
                        }}
                        to="/profile"
                        className="p-2 me-2 ms-5 d-none d-lg-block"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="#98694c" class="bi bi-person-fill" viewBox="0 0 16 16">
                          <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                        </svg>
                      </NavLink>
                      <div className="p-2 d-none d-lg-block">
                        <CartOffCanvas />
                      </div>
                      {/* <NavLink
                      style={({ isActive }) => {
                        return isActive ? { opacity: "100%", borderBottom: "2px solid" } : { opacity: "100%" };
                      }}
                      to="/checkout"
                      className="p-2 d-none d-lg-block"
                    >
                      <div className="position-relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="#98694c" class="bi bi-basket2-fill position-relative" viewBox="0 0 16 16">
                          <path d="M5.929 1.757a.5.5 0 1 0-.858-.514L2.217 6H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h.623l1.844 6.456A.75.75 0 0 0 3.69 15h8.622a.75.75 0 0 0 .722-.544L14.877 8h.623a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1.717L10.93 1.243a.5.5 0 1 0-.858.514L12.617 6H3.383L5.93 1.757zM4 10a1 1 0 0 1 2 0v2a1 1 0 1 1-2 0v-2zm3 0a1 1 0 0 1 2 0v2a1 1 0 1 1-2 0v-2zm4-1a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1z" />
                        </svg>
                        <span class="position-absolute top-1 start-100 translate-middle badge rounded-pill bg-danger" style={{ fontSize: "9px" }}>
                          {cartSize}
                        </span>
                      </div>
                    </NavLink> */}
                    </div>

                    {/* <NavDropdown title="Dropdown" id={`offcanvasNavbarDropdown-expand-${expand}`}>
                  <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action4">Another action</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action5">Something else here</NavDropdown.Item>
                </NavDropdown> */}
                  </Nav>
                  {/* <Form className="d-flex">
                <Form.Control type="search" placeholder="Search" className="me-2" aria-label="Search" />
                <Button variant="outline-success">Search</Button>
              </Form> */}
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        </div>

        {/* <div className="fixed-bottom px-4 py-2 container-sm">
        <div className="row rounded bg-white py-2 shadow">
          <div className="col text-center p-1">
            <NavLink
              style={({ isActive }) => {
                return isActive ? { opacity: "100%" } : { opacity: "50%" };
              }}
              to="/products"
              className="p-2 rounded rounded-2"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#98694c" class="bi bi-grid-fill" viewBox="0 0 16 16">
                <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3z" />
              </svg>
            </NavLink>
          </div>
          <div className="col text-center p-1">
            <NavLink
              style={({ isActive }) => {
                return isActive ? { opacity: "100%" } : { opacity: "50%" };
              }}
              to="/locations"
              className="p-2"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="#98694c" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
              </svg>
            </NavLink>
          </div>
          <div className="col text-center p-1">
            <NavLink
              style={({ isActive }) => {
                return isActive ? { opacity: "100%" } : { opacity: "50%" };
              }}
              to="/"
              className="p-2"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="#98694c" class="bi bi-house-heart-fill" viewBox="0 0 16 16">
                <path d="M7.293 1.5a1 1 0 0 1 1.414 0L11 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l2.354 2.353a.5.5 0 0 1-.708.707L8 2.207 1.354 8.853a.5.5 0 1 1-.708-.707L7.293 1.5Z" />
                <path d="m14 9.293-6-6-6 6V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V9.293Zm-6-.811c1.664-1.673 5.825 1.254 0 5.018-5.825-3.764-1.664-6.691 0-5.018Z" />
              </svg>
            </NavLink>
          </div>
          <div className="col text-center p-1">
            <NavLink
              style={({ isActive }) => {
                return isActive ? { opacity: "100%" } : { opacity: "50%" };
              }}
              to="/profile"
              className="p-2"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="#98694c" class="bi bi-person-fill" viewBox="0 0 16 16">
                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
              </svg>
            </NavLink>
          </div>
          <div className="col text-center p-1">
            <NavLink
              style={({ isActive }) => {
                return isActive ? { opacity: "100%" } : { opacity: "50%" };
              }}
              to="/checkout"
              className="p-2 position-relative"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="#98694c" class="bi bi-basket2-fill position-relative" viewBox="0 0 16 16">
                <path d="M5.929 1.757a.5.5 0 1 0-.858-.514L2.217 6H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h.623l1.844 6.456A.75.75 0 0 0 3.69 15h8.622a.75.75 0 0 0 .722-.544L14.877 8h.623a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1.717L10.93 1.243a.5.5 0 1 0-.858.514L12.617 6H3.383L5.93 1.757zM4 10a1 1 0 0 1 2 0v2a1 1 0 1 1-2 0v-2zm3 0a1 1 0 0 1 2 0v2a1 1 0 1 1-2 0v-2zm4-1a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1z" />
              </svg>
              <span class="position-absolute top-1 start-100 translate-middle badge rounded-pill bg-danger" style={{ fontSize: "9px" }}>
                {cartSize}
              </span>
            </NavLink>
          </div>
        </div>
      </div> */}
        <div className="fixed-bottom p-0 container-lg d-block d-lg-none border-top bg-white">
          <div className="container-sm ">
            <div className="row py-2 ">
              <div className="col text-center p-1">
                <NavLink
                  style={({ isActive }) => {
                    return isActive ? { opacity: "100%" } : { opacity: "50%" };
                  }}
                  to="/products"
                  className="p-2 rounded rounded-2"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#98694c" class="bi bi-grid-fill" viewBox="0 0 16 16">
                    <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3z" />
                  </svg>
                </NavLink>
              </div>
              <div className="col text-center p-1">
                <NavLink
                  style={({ isActive }) => {
                    return isActive ? { opacity: "100%" } : { opacity: "50%" };
                  }}
                  to="/locations"
                  className="p-2"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="#98694c" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                  </svg>
                </NavLink>
              </div>
              <div className="col text-center p-1">
                <NavLink
                  style={({ isActive }) => {
                    return isActive ? { opacity: "100%" } : { opacity: "50%" };
                  }}
                  to="/"
                  className="p-2"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="#98694c" class="bi bi-house-heart-fill" viewBox="0 0 16 16">
                    <path d="M7.293 1.5a1 1 0 0 1 1.414 0L11 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l2.354 2.353a.5.5 0 0 1-.708.707L8 2.207 1.354 8.853a.5.5 0 1 1-.708-.707L7.293 1.5Z" />
                    <path d="m14 9.293-6-6-6 6V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V9.293Zm-6-.811c1.664-1.673 5.825 1.254 0 5.018-5.825-3.764-1.664-6.691 0-5.018Z" />
                  </svg>
                </NavLink>
              </div>
              <div className="col text-center p-1">
                <NavLink
                  style={({ isActive }) => {
                    return isActive ? { opacity: "100%" } : { opacity: "50%" };
                  }}
                  to="/profile"
                  className="p-2"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="#98694c" class="bi bi-person-fill" viewBox="0 0 16 16">
                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                  </svg>
                </NavLink>
              </div>
              <div className="col text-center p-1">
                <CartOffCanvas />

                {/* <NavLink
                style={({ isActive }) => {
                  return isActive ? { opacity: "100%" } : { opacity: "50%" };
                }}
                to="/checkout"
                className="p-2 position-relative"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="#98694c" class="bi bi-basket2-fill position-relative" viewBox="0 0 16 16">
                  <path d="M5.929 1.757a.5.5 0 1 0-.858-.514L2.217 6H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h.623l1.844 6.456A.75.75 0 0 0 3.69 15h8.622a.75.75 0 0 0 .722-.544L14.877 8h.623a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1.717L10.93 1.243a.5.5 0 1 0-.858.514L12.617 6H3.383L5.93 1.757zM4 10a1 1 0 0 1 2 0v2a1 1 0 1 1-2 0v-2zm3 0a1 1 0 0 1 2 0v2a1 1 0 1 1-2 0v-2zm4-1a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1z" />
                </svg>
                <span class="position-absolute top-1 start-100 translate-middle badge rounded-pill bg-danger" style={{ fontSize: "9px" }}>
                  {cartSize}
                </span>
              </NavLink> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OffcanvasExample;
