import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer className=" text-white  bg-white  pt-2 mt-5 mb-5 mb-lg-1">
        <section className="pt-2 bg-primary">
          <div className="container  mt-5">
            <div className="row">
              <div className="col-6 col-md-4  mb-4 ">
                <div className="mb-5">
                  <h5 className=" mb-3">
                    <Link to="/" className=" text-decoration-none fw-bolder text-white ">
                      Furra Lumi
                    </Link>
                  </h5>
                  <p className="mb-2">Na ndiqni:</p>
                  <div className="d-flex flex-row">
                    <div className="pe-3">
                      <a href="https://www.facebook.com/furralumi/" className="" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#fff" class="bi bi-facebook" viewBox="0 0 16 16">
                          <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                        </svg>
                      </a>
                    </div>
                    <div className="pe-3">
                      <a href="https://www.instagram.com/furra.lumi/" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#fff" class="bi bi-instagram" viewBox="0 0 16 16">
                          <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="mb-2">Metodat e pagesës:</p>
                  <div className="d-flex flex-row">
                    <div className="pe-3">
                      <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-cash" viewBox="0 0 16 16">
                        <path d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
                        <path d="M0 4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V6a2 2 0 0 1-2-2H3z" />
                      </svg>
                    </div>
                    <div className="pe-3">
                      <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-credit-card" viewBox="0 0 16 16">
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z" />
                        <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center col-4 d-none d-md-block  mb-4">
                <h6 className=" mb-4">Na Kontaktoni</h6>
                <div className="mb-4">
                  <h6 className="fw-bold">Rruga Anton Çetta</h6>
                  <p className="mb-1">
                    <a href="tel:038221824" className="text-reset text-decoration-none">
                      038 221-824
                    </a>
                  </p>
                  <p className="mb-1">
                    <a href="tel:044115386" className="text-reset text-decoration-none">
                      044 115-386
                    </a>
                  </p>
                </div>
                <div className="mb-5">
                  <h6 className="fw-bold">Rruga Eqrem Çabej</h6>
                  <p className="mb-1">
                    <a href="tel:038545295" className="text-reset text-decoration-none">
                      038 545-295
                    </a>
                  </p>
                  <p className="mb-1">
                    <a href="tel:049115386" className="text-reset text-decoration-none">
                      049 115-386
                    </a>
                  </p>
                </div>
                <div>
                  <h6 className="fw-bold">E-Mail</h6>

                  <p className="mb-1">
                    <a href="mailto:furra_lumi@yahoo.com" className="text-reset text-decoration-none">
                      furra_lumi@yahoo.com
                    </a>
                  </p>
                </div>
              </div>

              <div className="text-end col-6 col-md-4   mb-md-0 mb-4">
                <div className="mb-5">
                  <h6 className=" fw-bold mb-3">
                    <Link to="/" className=" text-decoration-none fw-bolder text-white ">
                      Produktet
                    </Link>
                  </h6>
                  <p className="mb-1">
                    <Link to="/product/dreke" className=" text-decoration-none  text-white ">
                      Drekë
                    </Link>
                  </p>
                  <p className="mb-1">
                    <Link to="/product/torte" className=" text-decoration-none  text-white ">
                      Tortë
                    </Link>
                  </p>
                  <p className="mb-1">
                    <Link to="/product/embelsirat-individuale" className=" text-decoration-none  text-white ">
                      Ëmbëlsira
                    </Link>
                  </p>
                  <p className="mb-1">
                    <Link to="/product/pije" className=" text-decoration-none  text-white ">
                      Pije
                    </Link>
                  </p>
                </div>
                <div>
                  <p className="mb-1">
                    <Link to="/product/torte" className=" text-decoration-none  text-white ">
                      Lokacionet
                    </Link>
                  </p>
                  <p className="mb-1">
                    <Link to="/product/embelsirat-individuale" className=" text-decoration-none  text-white ">
                      Pyetjet e shpeshta
                    </Link>
                  </p>
                  <p className="mb-1">
                    <Link to="/product/pije" className=" text-decoration-none  text-white ">
                      Për ne
                    </Link>
                  </p>
                </div>
              </div>
              <div className="text-center col-12 d-block d-md-none  my-4">
                <h6 className=" mb-4">Na Kontaktoni</h6>
                <div className="mb-4">
                  <h6 className="fw-bold">Rruga Anton Çetta</h6>
                  <p className="mb-1">
                    <a href="tel:038221824" className="text-reset text-decoration-none">
                      038 221-824
                    </a>
                  </p>
                  <p className="mb-1">
                    <a href="tel:044115386" className="text-reset text-decoration-none">
                      044 115-386
                    </a>
                  </p>
                </div>
                <div className="mb-5">
                  <h6 className="fw-bold">Rruga Eqrem Çabej</h6>
                  <p className="mb-1">
                    <a href="tel:038545295" className="text-reset text-decoration-none">
                      038 545-295
                    </a>
                  </p>
                  <p className="mb-1">
                    <a href="tel:049115386" className="text-reset text-decoration-none">
                      049 115-386
                    </a>
                  </p>
                </div>
                <div>
                  <h6 className="fw-bold">E-Mail</h6>

                  <p className="mb-1">
                    <a href="mailto:furra_lumi@yahoo.com" className="text-reset text-decoration-none">
                      furra_lumi@yahoo.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="text-center p-3 bg-white" style={{ color: "#98694c" }}>
          © 2023 Copyright -<span className="fw-bold"> Furra Lumi</span>
        </div>
      </footer>
    </>
  );
}

export default Footer;
