import { React, useState, useContext } from "react";
import { CartContext } from "./cartComponent";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, NavLink } from "react-router-dom";
import { CartItems } from "./cartItems";

export function CartOffCanvas() {
  const [showCart, setShowCart] = useState(false);
  const { cartSize, cartSum } = useContext(CartContext);

  const toggleCart = () => setShowCart(!showCart);

  const ContinueButton = () => {
    if (cartSize === 0 || cartSize === [] || cartSize.length === 0) {
      return (
        <div className="p-3 border-top" style={{ width: "100%" }}>
          <div className="d-grid gap-2 pt-2">
            <Link className="btn btn-primary " to="/products" onClick={toggleCart}>
              Shfleto produktet
            </Link>
          </div>
        </div>
      );
    } else {
      return (
        <div className="p-3 border-top" style={{ width: "100%" }}>
          <div className="d-flex flex-row    align-items-center">
            <div className="me-auto">
              <h4 className="m-0">Totali</h4>
            </div>
            <div>
              <p className="fw-bold col m-0 fs-5 "> {cartSum.toFixed(2)} € </p>
            </div>
          </div>
          <div className="d-grid gap-2 ">
            <h6 className="m-0 text-secondary">Përfshin 18% TVSH</h6>
            <Link className="btn btn-primary mt-2" to="/checkout" onClick={toggleCart}>
              Bëje porosinë
            </Link>
            {/* <button onClick={toggleCart} className="btn btn-light">
              Vazhdo shfleto
            </button> */}
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div onClick={toggleCart}>
        {/* <div className="position-relative">
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="#98694c" class="bi bi-basket2-fill position-relative" viewBox="0 0 16 16">
            <path d="M5.929 1.757a.5.5 0 1 0-.858-.514L2.217 6H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h.623l1.844 6.456A.75.75 0 0 0 3.69 15h8.622a.75.75 0 0 0 .722-.544L14.877 8h.623a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1.717L10.93 1.243a.5.5 0 1 0-.858.514L12.617 6H3.383L5.93 1.757zM4 10a1 1 0 0 1 2 0v2a1 1 0 1 1-2 0v-2zm3 0a1 1 0 0 1 2 0v2a1 1 0 1 1-2 0v-2zm4-1a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1z" />
          </svg>
          <span class="position-absolute top-1 start-100 translate-middle badge rounded-pill bg-danger" style={{ fontSize: "9px" }}>
            {cartSize}
          </span>
        </div> */}
        <NavLink
          onClick={toggleCart}
          style={({ isActive }) => {
            return isActive ? { opacity: "100%" } : { opacity: "50%" };
          }}
          className="p-2 position-relative"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="#98694c" class="bi bi-basket2-fill position-relative" viewBox="0 0 16 16">
            <path d="M5.929 1.757a.5.5 0 1 0-.858-.514L2.217 6H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h.623l1.844 6.456A.75.75 0 0 0 3.69 15h8.622a.75.75 0 0 0 .722-.544L14.877 8h.623a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1.717L10.93 1.243a.5.5 0 1 0-.858.514L12.617 6H3.383L5.93 1.757zM4 10a1 1 0 0 1 2 0v2a1 1 0 1 1-2 0v-2zm3 0a1 1 0 0 1 2 0v2a1 1 0 1 1-2 0v-2zm4-1a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1z" />
          </svg>
          <span class="position-absolute top-1 start-100 translate-middle badge rounded-pill bg-danger" style={{ fontSize: "9px" }}>
            {cartSize}
          </span>
        </NavLink>
      </div>
      {/* <NavLink
          style={({ isActive }) => {
            return isActive ? { opacity: "100%" } : { opacity: "50%" };
          }}
          to="/checkout"
          className="p-2 position-relative"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="#98694c" class="bi bi-basket2-fill position-relative" viewBox="0 0 16 16">
            <path d="M5.929 1.757a.5.5 0 1 0-.858-.514L2.217 6H.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h.623l1.844 6.456A.75.75 0 0 0 3.69 15h8.622a.75.75 0 0 0 .722-.544L14.877 8h.623a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1.717L10.93 1.243a.5.5 0 1 0-.858.514L12.617 6H3.383L5.93 1.757zM4 10a1 1 0 0 1 2 0v2a1 1 0 1 1-2 0v-2zm3 0a1 1 0 0 1 2 0v2a1 1 0 1 1-2 0v-2zm4-1a1 1 0 0 1 1 1v2a1 1 0 1 1-2 0v-2a1 1 0 0 1 1-1z" />
          </svg>
          <span class="position-absolute top-1 start-100 translate-middle badge rounded-pill bg-danger" style={{ fontSize: "9px" }}>
            {cartSize}
          </span>
        </NavLink> */}
      <Offcanvas show={showCart} onHide={toggleCart} className="cartOffCanvas custom-max-width" placement="end" size="lg">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Shporta</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ maxHeight: "100%" }}>
          {/* // Add your cart content here */}
          <div className="p-3 text-center" style={cartSize === 0 || cartSize === [] || cartSize.length === 0 ? { width: "100%" } : { width: "100%", display: "none" }}>
            Shporta është e zbazët
          </div>
          {/* <div style={{ width: "100%" }} className="mb-auto CartScrollbarEdited overflow-y-auto">
            <p className="text-break">{JSON.stringify(cart)}</p>
            {cart.map((items, key) => {
              return (
                <div className=" pt-2 pb-4 mx-0 px-0 row mb-4 mt-0  border-bottom" key={items.product_id}>
                  <div className="col ps-0 pe-2 " style={{ maxWidth: "100px" }}>
                    <div className="ratio ratio-1x1 rounded" style={{ backgroundImage: `url(${items.image})`, backgroundSize: "cover", backgroundPosition: "50%" }}></div>
                    
                  </div>
                  <div className="col ps-1 pe-0">
                    <div className="position-relative pb-3">
                      <p className="p-0 m-0 pe-4  col">{items.name}</p>
                      <p className=" col m-0 text-secondary " style={{ fontSize: "14px" }}>
                        {(items.price * 1).toFixed(2)} €
                      </p>
                      <button className="btn btn-light p-0 pb-1 ps-1 pe-1 m-0 position-absolute top-0 end-0" value={key} onClick={() => handleRemove(key)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                      </button>
                    </div>
                    <div className="d-flex flex-row align-items-end pt-2">
                      <div className=" d-flex flex-row align-items-center  me-auto">
                        <button className="btn btn-light ps-0 pe-2 py-0 m-0 border-0 minus" value={key} onClick={() => handleMinus(key)} style={{ backgroundColor: "#ffffff" }}>
                          <span className="p-0 m-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="23" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16">
                              <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                            </svg>
                          </span>
                        </button>
                        <div className="">
                          <p className="fw-bold p-0 m-0 px-2">{items.quantity}</p>
                        </div>
                        <button className="btn btn-light px-2 py-0 m-0 border-0 plus" value={key} onClick={() => handlePlus(key)} style={{ backgroundColor: "#ffffff" }}>
                          <span className="p-0 m-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="22" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                          </span>
                        </button>
                      </div>
                      <div className="row ">
                        <p className="fw-bold col m-0 "> {(items.price * 1 * items.quantity).toFixed(2)} €</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div> */}
          <CartItems />
        </Offcanvas.Body>
        {/* <div className="p-3 border-top" style={cart === [] || cart.length === 0 ? { width: "100%", display: "none" } : { width: "100%" }}>
          <div className="d-flex flex-row    align-items-center">
            <div className="me-auto">
              <h4 className="m-0">Totali</h4>
            </div>
            <div>
              <p className="fw-bold col m-0 fs-5 "> {cartSum.toFixed(2)} € </p>
            </div>
          </div>
          <div className="d-grid gap-2 pt-3">
            <Link className="btn btn-primary " to="/checkout" onClick={toggleCart}>
              Bëje porosinë
            </Link>
          </div>
        </div> */}
        <ContinueButton />
      </Offcanvas>
    </>
  );
}
