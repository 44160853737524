import { React, useEffect, useState } from "react";
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";

// const api = new WooCommerceRestApi({
//   url: "https://furralumi.com",
//   consumerKey: "ck_82c4566816c39e2346a3a967836047ca3a194e1c",
//   consumerSecret: "cs_b1e1ddf62d553145ccbd746260868768de82f616",
//   version: "wc/v3",
// });

const api = new WooCommerceRestApi({
  url: "https://cms.furralumi.com",
  consumerKey: "ck_4a84f47a47cd79d4a622e3007e822cf3246d64c3",
  consumerSecret: "cs_35e53e35fe4cd9dda51f78601c3037de2c60afff",
  version: "wc/v3",
});

// const api = new WooCommerceRestApi({
//   url: "https://cms.furralumi.com",
//   consumerKey: "ck_ff22d21eba4a85d13e23c4c40f901471100a8f01",
//   consumerSecret: "cs_4406b9ebee221134c3bc582952ac6208b4b59645",
//   version: "wc/v3",
// });

function Orders() {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    fetchOrders();
  }, []);

  let fetchOrders = () => {
    api
      .get("orders", {
        per_page: 20,
      })
      .then((response) => {
        if (response.status === 200) {
          setOrders(response.data);
        }
      })
      .catch((error) => {});
  };

  return (
    <div className="App">
      <table>
        <thead>
          <tr>
            <th>#ID</th>
            <th>Amount</th>
            <th>Name</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order, index) => {
            return (
              <tr key={index}>
                <td>{order.id}</td>
                <td>{order.total}</td>
                <td>{order.billing && order.billing.first_name}</td>
                <td>{order.billing && order.billing.email}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
      <a href="/">back to homepage</a>
    </div>
  );
}

export default Orders;
