function Locations() {
  return (
    <>
      <div className="container">
        <h2 className="text-center pb-5 pt-4 mb-0 text-primary">Mund të na gjeni në 2 lokacione</h2>
      </div>
      <div className="container pb-5">
        <div className="p-3 bg-primary rounded-4">
          <div className="row ">
            <div className="col-12 col-lg-8 mb-3 mb-lg-0">
              <iframe
                title="location"
                className="rounded"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5868.578291386467!2d21.1520126!3d42.6552277!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13549ee7f49a3f17%3A0xae34b33c5e6516d!2sFurra%20%22Lumi%22!5e0!3m2!1sen!2s!4v1679158340181!5m2!1sen!2s"
                width="100%"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              />
            </div>
            <div className="col-12 col-lg-4 text-center text-lg-start justify-content-center align-self-center text-white">
              <p className=" fs-4 m-0">Pika 1</p>
              <h2 className="mb-4 fw-semibold">Rruga Anton Çetta</h2>
              <a className="btn btn-light" href="https://goo.gl/maps/ZFSU8knN6cTi11raA" target="_blank" rel="noreferrer">
                Shiko në Map
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="py-5">
        <div className="container ">
          <div className="bg-white border border-2 rounded-4 p-3">
            <div className="row ">
              <div className="mb-4 col-12 col-lg-4  text-center text-lg-end justify-content-center align-self-center text-white">
                <p className=" fs-4 m-0" style={{ color: "#98694c" }}>
                  Pika 2
                </p>
                <h2 className="mb-4 fw-semibold" style={{ color: "#98694c" }}>
                  Rruga Eqrem Qabej
                </h2>
                <a className="btn btn-light" href="https://goo.gl/maps/3pawuymYampassev8" target="_blank" rel="noreferrer">
                  Shiko në Map
                </a>
              </div>
              <div className="col-first col-12 col-lg-8 mb-3 mb-lg-0">
                <iframe
                  title="location"
                  className="rounded"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5868.578291386467!2d21.1520126!3d42.6552277!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13549f3294aa0f9b%3A0xbea30e48168f795e!2sFurra%20%22Lumi%22!5e0!3m2!1sen!2s!4v1679159702831!5m2!1sen!2s"
                  width="100%"
                  height="450"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Locations;
