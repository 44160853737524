import { React, useEffect, useState } from "react";
import { useContext } from "react";
import { CartContext } from "../Cart/cartComponent";
import { message } from "antd";
import WooAPI from "../API/woocommerce-api";

function AddToCart({ item }) {
  // Fetched Product Data
  const [fetchedData, setFetchedData] = useState(false);

  // Add to Cart Button
  const { addItemToCart } = useContext(CartContext);
  const [stockQuantity, setStockQuantity] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [quantityStep, setQuantityStep] = useState(1);
  const [disabledButton, setDisabledButton] = useState(true);

  // Variations
  const [variationId, setVariationId] = useState(0);
  const [variations, setVariations] = useState([]);
  const [variationName, setVariationName] = useState("");
  const [variationIndex, setVariationIndex] = useState(0);

  // Simple product
  const [simple, setSimple] = useState([]);

  //temporary
  const [price, setPrice] = useState(item.price);
  const [manageStock, setManageStock] = useState(true);
  // console.log("Item price: ", price * quantity * quantityStep);

  useEffect(() => {
    setQuantity(1);
    setFetchedData(false);
    setDisabledButton(true);
    setVariationId(0);
    setVariationName("");
    setQuantityStep(1);
    setPrice(item.price);
  }, [item]);

  useEffect(() => {
    console.log("Updated VariationId:", variationId);
    console.log("Updated Variation Height:", quantityStep);
    if (item.type === "variable" && variations !== [] && fetchedData === true) {
      console.log("Variation index: ", variationIndex);
      console.log("Variation Stock quantity: ", variations[variationIndex].stock_quantity);
      setStockQuantity(variations[variationIndex].stock_quantity);
      setPrice(variations[variationIndex].price * 1);
    }
  }, [variationId]);

  const IncrementButton = () => {
    const handleClick = () => {
      console.log(item);
      if (quantity < stockQuantity) {
        setQuantity(quantity + 1);
      } else {
        message.warning("Për momentin nuk kemi sasi më të madhe të stokut!");
      }
    };

    return (
      <button className="btn btn-light fw-bolder" style={{ width: "100%" }} onClick={handleClick}>
        +
      </button>
    );
  };

  const DecrementButton = () => {
    const handleClick = () => {
      if (quantity > 1) {
        setQuantity(quantity - 1);
      } else {
        // message.error("Sasia nuk mund të jetë më pak se 1!");
      }
    };

    return (
      <button className="btn btn-light fw-bolder " style={{ width: "100%" }} onClick={handleClick}>
        -
      </button>
    );
  };

  const handleAddToCart = () => {
    if (fetchedData === true) {
      if (item.type === "variable" && variationId === 0) {
        return message.warning("Zgjedhni njërin nga opcionet.");
      }
      if (item.type === "simple") {
        setVariationId(0);
      }
      addItemToCart(
        {
          product_id: item.id,
          variation_id: variationId,
          variation_name: variationName,
          quantity: quantity,
          stock: stockQuantity,
          name: item.name,
          image: item.images[0].src,
          price: price,
          step: quantityStep,
          manage_stock: manageStock,
          type: item.type,
        },
        quantityStep,
        quantity
      );
    } else {
      return message.warning("Duke u ngarkuar...");
    }
  };

  const LoadVariations = () => {
    if (item.type === "variable" && fetchedData === false) {
      WooAPI.get("products/" + item.id + "/variations")
        .then((response) => {
          if (response.status === 200) {
            setVariations(response.data);
            // console.log("item.attributes", item.attributes[0].name);
            // console.log("variations:", variations);
            // console.log("Item variations:", item.attributes[0]);
            setFetchedData(true);
            // setStockQuantity(item.stock_quantity);
            console.log("Variations");
            console.log(response.data);

            // if (item.stock_quantity > 0) {
            //   setDisabledButton(false);
            // }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (item.type === "simple" && fetchedData === false) {
      WooAPI.get("products/" + item.id)
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            setFetchedData(true);
            setSimple(response.data);
            console.log("simple", simple);
            setStockQuantity(response.data.stock_quantity);
            // if (response.data.stock_quantity > 0) {
            //   setDisabledButton(false);
            // }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (fetchedData === false) {
      return (
        <div className="pb-4">
          <p className="mb-2">Duke u ngarkuar...</p>
        </div>
      );
    } else if (item.type === "variable" && fetchedData !== false) {
      return (
        <div className="pb-4">
          <p className="mb-2">{item.attributes[0].name}</p>
          <div className="d-flex flex-wrap">
            {variations.map((item, index) => {
              return (
                <button
                  className={`btn btn-light btn-outline-secondary me-2 mb-2 order-${item.menu_order}`}
                  value={index}
                  style={item.id === variationId ? { border: "3px solid", fontSize: "14px" } : { fontSize: "14px" }}
                  onClick={() => (
                    setVariationId(item.id), setQuantityStep(item.dimensions.height), setVariationName(item.attributes[0].option), setVariationIndex(index), setManageStock(item.manage_stock)
                  )}
                >
                  {item.attributes[0].option}
                  {/* {variations[index].id} */}
                  {/* {item.id} */}
                </button>
              );
            })}
          </div>
        </div>
      );
    }
    //   if (item.type === "variable" && fetchedData !== false) {
    //     return (
    //       <div className="pb-4">
    //         <p className="mb-2">{item.attributes[0].name}</p>
    //         <div className="d-flex flex-wrap">
    //           {variations.map((item, index) => {
    //             return (
    //               <button
    //                 className={`btn btn-light btn-outline-secondary me-2 mb-2 order-${item.menu_order}`}
    //                 value={index}
    //                 style={item.id === variationId ? { border: "3px solid", fontSize: "14px" } : { fontSize: "14px" }}
    //                 onClick={() => (
    //                   setVariationId(item.id), setQuantityStep(item.dimensions.height), setVariationName(item.attributes[0].option), setVariationIndex(index), setManageStock(item.manage_stock)
    //                 )}
    //               >
    //                 {item.attributes[0].option}
    //                 {/* {variations[index].id} */}
    //                 {/* {item.id} */}
    //               </button>
    //             );
    //           })}
    //         </div>
    //       </div>
    //     );
    //   } else if (item.type === "variable") {
    //     return (
    //       <div className="pb-4">
    //         <p className="mb-2">Duke u ngarkuar...</p>
    //       </div>
    //     );
    //   }

    console.log("quantity:", quantity);
    console.log("stockQuantity:", stockQuantity);
  };

  return (
    <>
      <div className="mt-2">
        <div className="pb-4">
          <p className="p-0 m-0 fs-5">{(price * 1 * quantityStep).toFixed(2)} €</p>

          {/* <p className="mb-2">Variacionet</p> */}
          {/* {item.attributes[0].options.map((attribute) => {
            return (
              <button className="btn btn-light btn-outline-secondary me-2 mb-2" style={{ fontSize: "14px" }}>
                {attribute}
              </button>
            );
          })} */}
        </div>
        <LoadVariations />
        <div className="col-12 col-md-10 col-lg-7">
          <div className="">
            <div className="d-flex mb-2 ">
              <div className="col-4 pe-0">
                <DecrementButton />
              </div>
              <div className="col-4 px-1 ">
                <div className="pb-0">
                  <input type="text" id="addToCart" value={quantity} className="form-control true text-center " aria-describedby="nameHelpBlock" />
                  {/* <select type="text" id="addToCart" value={quantity} onChange={handleSelectChange} className="form-control true text-center " aria-describedby="nameHelpBlock">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select> */}
                  <div id="nameHelpBlock" className="form-text"></div>
                </div>
              </div>
              <div className="col-4 ps-0">
                <IncrementButton />
              </div>
            </div>
            <div className="d-grid gap-2">
              {/* <button className="btn btn-primary " onClick={handleAddToCart}> */}
              <button className="btn btn-primary py-2" disabled={false} onClick={handleAddToCart}>
                {/* {fetchedData ? (stockQuantity > 0 ? "Shto në shportë" : "S'ka stok") : "Shto në shportë..."} */}
                {fetchedData
                  ? item.type === "simple"
                    ? stockQuantity > 0
                      ? "Shto në shportë"
                      : "S'ka stok"
                    : variationId === 0
                    ? "Zgjedh njërin opcion"
                    : stockQuantity > 0
                    ? "Shto në shportë"
                    : "S'ka stok"
                  : "Shto në shportë..."}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <div>
        <p className="pt-3 mb-1 ">Development details</p>
        <p className="">
          Id : {item.id}
          <br />
          vId: {variationId}
          <br />
          stock: {stockQuantity}
        </p>
      </div> */}
      {/* {resetStatesOnReload()}; */}
    </>
  );
}

export default AddToCart;
