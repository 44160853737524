import { React, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import RelatedProducts from "./relatedProducts";
import { Helmet } from "react-helmet";
import AddToCart from "../components/addToCart";
import allProductsData from "../data/allProductsData";

const defaultImg = "https://furralumi.com/wp-content/uploads/2022/05/Fotot-do-te-shtohen-se-shpejti_2.jpg";

function Product() {
  const { slug } = useParams();
  const [imageIndex, setImageIndex] = useState(0);

  const filteredByIdProduct = allProductsData.filter((item) => item.slug === slug);
  const currentProduct = filteredByIdProduct[0];
  console.log(currentProduct);
  // if (loading == false) {
  //   return (
  //     <>
  //       <div class="d-flex justify-content-center">
  //         <div class="spinner-border text-secondary my-5" role="status">
  //           <span class="visually-hidden">Loading...</span>
  //         </div>
  //       </div>
  //     </>
  //   );
  // }
  useEffect(() => {
    setImageIndex(0);
  }, [currentProduct]);

  const handleImageIndex = (index) => {
    setImageIndex(index);
  };

  return (
    <>
      <Helmet>
        <title>Furra Lumi - {currentProduct.name}</title>
      </Helmet>

      <div className="container">
        <div className="pt-2 pb-1 d-flex" style={{ fontSize: "14px" }}>
          <Link to="/" className="text-secondary text-decoration-none">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" style={{ marginTop: "-4px" }} fill="#6c757d" class="bi bi-house-heart-fill" viewBox="0 0 16 16">
              <path d="M7.293 1.5a1 1 0 0 1 1.414 0L11 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l2.354 2.353a.5.5 0 0 1-.708.707L8 2.207 1.354 8.853a.5.5 0 1 1-.708-.707L7.293 1.5Z" />
              <path d="m14 9.293-6-6-6 6V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V9.293Zm-6-.811c1.664-1.673 5.825 1.254 0 5.018-5.825-3.764-1.664-6.691 0-5.018Z" />
            </svg>
          </Link>
          <p className="px-2"> / </p>
          <Link to="/products" className="text-secondary text-decoration-none">
            Produktet
          </Link>
          <p className="px-2"> / </p>
          <Link to={"/product/" + currentProduct.categories[0].slug} className="text-secondary text-decoration-none">
            {currentProduct.categories[0].name}
          </Link>
        </div>

        {/* <div className="p-2 rounded rounded-3 shadow"> */}
        <div className="p-3 rounded rounded-3 bg-white shadow-sm">
          <div className="row">
            <div className="col-12 col-md-6">
              <div>
                {currentProduct.images[imageIndex] ? (
                  <img alt="" src={currentProduct.images[imageIndex].src} className="rounded" style={{ width: "100%" }}></img>
                ) : (
                  <img alt="" src={defaultImg} className="rounded" style={{ width: "100%" }}></img>
                )}
                {/* {console.log("Images array:", currentProduct.images)}
                {console.log("Images index", imageIndex)} */}
              </div>
              <div className="row px-2 py-1">
                {currentProduct.images.map((image, index) => {
                  return (
                    <div className="col-3 p-1" style={index !== imageIndex ? { opacity: 0.5 } : null}>
                      <div
                        className="rounded ratio ratio-4x3"
                        value={index}
                        onClick={() => handleImageIndex(index)}
                        // style={{ backgroundImage: `url(${images.src})`, backgroundSize: "cover", backgroundPositionX: "50%" } + (imageIndex !== index) ? { opacity: 0.5 } : null}
                        style={{ backgroundImage: `url(${image.src})`, backgroundSize: "cover", backgroundPositionX: "50%" }}
                      ></div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="col-12 col-md-6">
              <p className="mb-0 mt-2 fs-3 fw-semibold">{currentProduct.name}</p>
              <p className="p-0 m-0 d-none">{(currentProduct.price * 1).toFixed(2)} €</p>
              <div>
                <AddToCart item={currentProduct} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={currentProduct.description ? "container mt-5" : "d-none"}>
        <div className="p-0 rounded rounded-3 bg-white p-3 shadow-sm">
          <h5 className="pb-3">Detajet e produktit</h5>
          {/* {JSON.stringify(currentProduct.description)} */}
          {/* {currentProduct.description.replace(/"/g, " ")} */}

          <div dangerouslySetInnerHTML={{ __html: currentProduct.description }} />
        </div>
      </div>

      <RelatedProducts props={currentProduct.related_ids} />
    </>
  );
}

export default Product;
