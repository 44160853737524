// MainLayout.js
import React from "react";
import OffcanvasExample from "../components/navbar";
import Footer from "../components/footer";

export default function MainLayout({ children }) {
  return (
    <>
      <OffcanvasExample />
      <main>{children}</main>
      <Footer />
    </>
  );
}
