import React from "react";
import allCategoriesData from "../data/allCategoriesData";
import { NavLink } from "react-router-dom";

export default function CategoriesSection() {
  return (
    <div className="container">
      <div className=" rounded rounded-3 bg-white p-3 shadow-sm">
        <h5 className="pb-3 text-center ">Shfleto sipas kategorive</h5>

        <div className="row g-3 justify-content-center">
          {allCategoriesData.map((category) => {
            return (
              <div
                className=" col-6 col-sm-4 col-md text-center  "
                // style={{ backgroundImage: `url(${category.image.src})`, backgroundSize: "cover" }}
              >
                <NavLink
                  style={({ isActive }) => {
                    return isActive ? { opacity: "100%", backgroundColor: "#98694c", color: "#ffffff" } : { opacity: "100%", backgroundColor: "#fafafa", color: "#98694c" };
                  }}
                  to={category.slug === "products" ? "/products" : "/product/" + category.slug}
                  className=" btn container-fluid fw-bold   row align-items-center"
                >
                  {/* <div className="p-1" style={{ width: "100%" }}> */}
                  {category.name}
                  {/* </div> */}
                </NavLink>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
