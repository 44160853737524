const allCategoriesData = [
  {
    id: 24,
    name: "Drekë",
    slug: "dreke",
    parent: 0,
    description: "",
    display: "default",
    image: {
      id: 98,
      date_created: "2023-02-11T14:24:25",
      date_created_gmt: "2023-02-11T14:24:25",
      date_modified: "2023-02-11T14:24:25",
      date_modified_gmt: "2023-02-11T14:24:25",
      src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/mantia-scaled-2.jpg",
      name: "mantia-scaled-2.jpg",
      alt: "",
    },
    menu_order: 2,
    count: 8,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/categories/24",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/categories",
        },
      ],
    },
  },
  {
    id: 27,
    name: "Tortë",
    slug: "torte",
    parent: 0,
    description: "",
    display: "default",
    image: {
      id: 134,
      date_created: "2023-02-11T14:25:07",
      date_created_gmt: "2023-02-11T14:25:07",
      date_modified: "2023-02-11T14:25:07",
      date_modified_gmt: "2023-02-11T14:25:07",
      src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/torte-millka_6.jpg",
      name: "torte-millka_6.jpg",
      alt: "",
    },
    menu_order: 5,
    count: 4,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/categories/27",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/categories",
        },
      ],
    },
  },
  {
    id: 22,
    name: "Ëmbëlsira",
    slug: "embelsirat-individuale",
    parent: 0,
    description: "",
    display: "default",
    image: {
      id: 103,
      date_created: "2023-02-11T14:24:35",
      date_created_gmt: "2023-02-11T14:24:35",
      date_modified: "2023-02-11T14:24:35",
      date_modified_gmt: "2023-02-11T14:24:35",
      src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/mascarpone_3.jpg",
      name: "mascarpone_3.jpg",
      alt: "",
    },
    menu_order: 3,
    count: 13,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/categories/22",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/categories",
        },
      ],
    },
  },
  {
    id: 21,
    name: "Pije",
    slug: "pije",
    parent: 0,
    description: "",
    display: "default",
    image: {
      id: 76,
      date_created: "2023-02-11T14:24:09",
      date_created_gmt: "2023-02-11T14:24:09",
      date_modified: "2023-02-11T14:24:09",
      date_modified_gmt: "2023-02-11T14:24:09",
      src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/Frutomania_Mjeder.jpg",
      name: "Frutomania_Mjeder.jpg",
      alt: "",
    },
    menu_order: 4,
    count: 10,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/categories/21",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/categories",
        },
      ],
    },
  },

  {
    id: 16,
    name: "Të gjitha",
    slug: "products",
    parent: 0,
    description: "",
    display: "default",
    image: {
      id: 134,
      date_created: "2023-02-11T14:25:07",
      date_created_gmt: "2023-02-11T14:25:07",
      date_modified: "2023-02-11T14:25:07",
      date_modified_gmt: "2023-02-11T14:25:07",
      src: "https://www.cms.furralumi.com/wp-content/uploads/2023/02/torte-millka_6.jpg",
      name: "torte-millka_6.jpg",
      alt: "",
    },
    menu_order: 6,
    count: 1,
    _links: {
      self: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/categories/16",
        },
      ],
      collection: [
        {
          href: "https://www.cms.furralumi.com/wp-json/wc/v3/products/categories",
        },
      ],
    },
  },
];
export default allCategoriesData;
