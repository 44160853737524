import { React, useEffect, useState } from "react";
import WooAPI from "../API/woocommerce-api";
import { useContext } from "react";
import { CartContext } from "../Cart/cartComponent";
// import { Toast } from "bootstrap";
import { message } from "antd";
import { Link, useNavigate, useHistory } from "react-router-dom";
import { CartItems } from "../Cart/cartItems";
import { Form, Button } from "react-bootstrap";
const bannerCheckout = "https://www.cms.furralumi.com/wp-content/uploads/2023/04/furra-lumi-2-interior_1-scaled-1.webp";

//set paid is true otherwise confirmation email wont get sent
const orderData = {
  payment_method: "bacs",
  payment_method_title: "Cash",
  set_paid: true,
  billing: {
    first_name: "Default",
    last_name: "",
    address_1: "",
    address_2: "",
    city: "Prishtina",
    state: "PR",
    postcode: "1001",
    country: "AL",
    email: "@gmail.com",
    phone: "(555) 555-5555",
  },
  line_items: [],
  meta_data: [
    {
      key: "location",
      value: "",
    },
    {
      key: "pickup",
      value: "",
    },
    {
      key: "pickup_time",
      value: "",
    },
    {
      key: "pickup_date",
      value: "",
    },
  ],
  // shipping_lines: [
  //   {
  //     method_id: "flat_rate",
  //     method_title: "Flat Rate",
  //     total: "10.00",
  //   },
  // ],
};

let orderNewData = orderData;

function Checkout() {
  const { cart, clearCart, reducedCart, removeItemFromCart, cartSum, addItemToCart } = useContext(CartContext);

  //Checkout
  const [activeStep, setActiveStep] = useState(1);

  //Form Data
  const [formData, setFormData] = useState({ name: "", email: "", telephone: "", location: "", pickup: "", pickup_time: "", pickup_date: "", payment_method: "cod" });

  const [formErrors, setFormErrors] = useState({});
  const [time, setTime] = useState(null);
  const [date, setDate] = useState(null);

  //Hapi pare
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [firstStep, setFirstStep] = useState(false);

  //Hapi dyte
  const [lokacioni, setLokacioni] = useState(false);
  const [secondStep, setSecondStep] = useState(false);

  //Hapi i trete
  // const [kurDoTaMerrni, setKurDoTaMerrni] = useState(0);
  const [kurDoTaMerrni, setKurDoTaMerrni] = useState("");
  const [thirdStep, setThirdStep] = useState(false);

  //Redirects to thank you page
  const navigate = useNavigate();

  function handleChange(event) {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    console.log("Form Data", formData);
    console.log("OrderNewData: ", orderNewData);
    orderNewData.billing.first_name = formData.name;
    orderNewData.billing.email = formData.email;
    orderNewData.billing.phone = formData.telephone;

    orderNewData.meta_data[0].value = formData.location;
    orderNewData.meta_data[1].value = formData.pickup;
    orderNewData.meta_data[2].value = formData.pickup_time;
    orderNewData.meta_data[3].value = formData.pickup_date;
  }

  function handleSubmit(event) {
    event.preventDefault();
    console.log("Working");
    const errors = {};
    if (formData.name.trim() === "") {
      errors.name = "Plotësoni emrin!";
    }

    if (formData.email.trim() === "") {
      errors.email = "Plotësoni e-mail adresën!";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = "Jepni një emai-l adresë valide!";
    }

    if (formData.telephone.trim() === "") {
      errors.telephone = "Plotësoni numrin e telefonit!";
    } else if (!/^[0-9]*$/.test(formData.telephone)) {
      errors.telephone = "Shkruani vetëm numra!";
    } else if (formData.telephone.length !== 9) {
      errors.telephone = "Jepni numrin e plotë!";
    } else if (formData.telephone[0] !== "0" && formData.telephone[1] !== "4") {
      errors.telephone = "Numri duhet të filloj me 04!";
    }

    if (formData.location.trim() === "") {
      errors.location = "Zgjedhni lokacionin!";
    }
    if (formData.payment_method.trim() === "") {
      errors.payment_method = "Zgjedhni mënyrën e pagesës!";
    }

    if (formData.pickup.trim() === "") {
      errors.pickup = "Zgjedhni njërin nga opcionet!";
    }

    if (formData.pickup.trim() === "later_today" || formData.pickup.trim() === "another_day") {
      if (formData.pickup_time.trim() === "") errors.pickup_time = "Zgjedhni kohën se kur do ta merrni porosinë!";
    }

    if (formData.pickup.trim() === "another_day") {
      if (formData.pickup_date.trim() === "") errors.pickup_date = "Zgjedhni datën se kur do ta merrni porosinë!";
    }

    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      // submit form
      WooAPI.post("orders", orderNewData)
        .then((response) => {
          console.log(response.data);
          message.success("Porosia juaj është pranuar!");
          // localStorage.removeItem("localCartData");
          orderNewData.line_items = [];
          clearCart();
          // if (Notification.permission !== "granted") {
          //   // Notification.requestPermission();
          // }
          // if (Notification.permission === "granted") {
          //   new Notification("New message from Furra Lumi", {
          //     body: "You have a new message!",
          //     icon: "path/to/icon.png",
          //   });
          // }
          navigate("/checkout/order-received");
        })
        .catch((error) => {
          console.log(error.response.data);
          message.error("Ka ndodhur një gabim!");
        });
    } else {
      // window.location.assign("#section-1");
      // window.scrollTo(0, 200);
    }
  }

  function LocationSelector(props) {
    const { value, onChange, error } = props;

    return (
      <div className="ps-2 d-flex flex-column align-items-start">
        <div className="btn btn-outline-primary is-invalid mb-2">
          <Form.Check type="radio" name="location" value="pika_pare" checked={value === "pika_pare"} onChange={onChange} label="Rruga Anton Çetta" id="pika_pare" />
        </div>
        <div className="btn btn-outline-primary is-invalid">
          <Form.Check type="radio" name="location" value="pika_dyte" checked={value === "pika_dyte"} onChange={onChange} label="Rruga Eqrem Çabej" id="pika_dyte" />
        </div>
        <Form.Control.Feedback className="is-invalid" type="invalid" isInvalid={error}>
          {error}
        </Form.Control.Feedback>
      </div>
    );
  }

  function PickupTimeSelector(props) {
    const { value, onChange, error } = props;

    return (
      <div className="ps-2 d-flex flex-column align-items-start">
        <div className="btn btn-outline-primary is-invalid mb-2">
          <Form.Check type="radio" name="pickup" value="asap" checked={value === "asap"} onChange={onChange} label="Sa më shpejt" id="asap" />
        </div>
        <div className="btn btn-outline-primary is-invalid mb-2">
          <Form.Check type="radio" name="pickup" value="later_today" checked={value === "later_today"} onChange={onChange} label="Sot më vonë" id="later_today" />
        </div>
        <div className="btn btn-outline-primary is-invalid">
          <Form.Check type="radio" name="pickup" value="another_day" checked={value === "another_day"} onChange={onChange} label="Zgjedh ditën dhe kohën" id="another_day" />
        </div>
        <Form.Control.Feedback className="is-invalid" type="invalid" isInvalid={error}>
          {error}
        </Form.Control.Feedback>
        {/* <Form.Control.Feedback type="invalid">{formErrors.name}</Form.Control.Feedback> */}
      </div>
    );
  }

  function PaymentOptions(props) {
    const { value, onChange, error } = props;

    return (
      <div className="">
        <div className="btn btn-outline-primary is-invalid mb-2">
          <Form.Check type="radio" name="payment_method" value="cod" checked={value === "cod"} onChange={onChange} label="Paguaj me para në dorë - CASH" id="cod" />
        </div>
        {/* <div className="btn btn-outline-primary is-invalid mb-2">
          <Form.Check type="radio" name="payment_method" value="card" disabled="true" checked={false} onChange={onChange} label="Paguaj online - CARD" id="card" />
        </div> */}
        <Form.Control.Feedback className="is-invalid" type="invalid" isInvalid={error}>
          {error}
        </Form.Control.Feedback>
        {/* <Form.Control.Feedback type="invalid">{formErrors.name}</Form.Control.Feedback> */}
      </div>
    );
  }

  const BejePorosine = () => {
    return (
      <div>
        <div id="fl_payment_method" className="shadow-sm p-3 rounded bg-white my-3  ">
          <p>Mënyra e pagesës</p>
          <div className="ps-2">
            {/* <div className="pb-2 d-flex">
              <input id="cash" className="me-2 " type="radio" name="payment_method" />
              <label className="btn btn-outline-primary text-start" for="cash">
                <p className="m-0 text-start">Paguaj me para në dorë - CASH</p>
                <span style={{ fontSize: "12px" }}>Paguaj me para në dorë gjatë pranimin të porosisë.</span>
              </label>
            </div> */}
            {/* <div className="pb-2">
                    <input id="credit_card" className="me-2" type="radio" name="payment_method" disabled />
                    <label className="btn border border-primary text-start" disabled for="credit_card" style={{ opacity: "0.4" }}>
                      <p className="m-0 text-start">Paguaj me kartelë </p>
                      <span style={{ fontSize: "12px" }}>...së shpejti në dispozicion</span>
                    </label>
                  </div> */}
            <Form.Group controlId="payment_method">
              <PaymentOptions value={formData.payment_method} onChange={handleChange} error={formErrors.payment_method} isInvalid={formErrors.payment_method} />
            </Form.Group>
            <div>
              <p className=" alert alert-warning mb-0 mt-4 text-center" style={{ fontSize: "14px" }}>
                Pagesat online me kartelë do të jen së shpejti në dispozicion.
              </p>
            </div>
          </div>
          <div id="lokacionetError" class="errorMessage"></div>
        </div>

        <div className="shadow-sm p-3 rounded mt-4 mb-5 bg-white">
          <div>
            <p className=" alert alert-success mb-0 text-center mb-3" style={{ fontSize: "14px" }}>
              Ju do të pranoni një thirrje, për konfirmimin e porosisë tuaj!
            </p>
          </div>
          <div className="d-grid gap-2">
            {/* <button className="btn btn-primary px-auto" onClick={handleClick}>
              Bëje Porosinë
            </button> */}
            <Button type="submit">Bëje Porosinë</Button>
          </div>
        </div>
      </div>
    );
  };

  // let handleClick = (event) => {
  //   event.preventDefault();
  //   console.log("Reduced cart:", reducedCart);

  //   if (fullName === "" || email === "" || number === "") {
  //     // console.log(orderNewData);
  //     message.error("Ju lutem plotësoni te gjitha fushat!");
  //   } else {
  //     WooAPI.post("orders", orderNewData)
  //       .then((response) => {
  //         console.log(response.data);
  //         message.success("Porosia juaj është pranuar!");
  //         // localStorage.removeItem("localCartData");
  //         orderNewData.line_items = [];
  //         clearCart();
  //         // if (Notification.permission !== "granted") {
  //         //   // Notification.requestPermission();
  //         // }
  //         // if (Notification.permission === "granted") {
  //         //   new Notification("New message from Furra Lumi", {
  //         //     body: "You have a new message!",
  //         //     icon: "path/to/icon.png",
  //         //   });
  //         // }
  //         navigate("/checkout/order-received");
  //       })
  //       .catch((error) => {
  //         console.log(error.response.data);
  //         message.error("Ka ndodhur një gabim!");
  //       });
  //   }
  // };

  useEffect(() => {
    orderNewData.billing.first_name = fullName;
    orderNewData.billing.email = email;
    orderNewData.billing.phone = number;
  }, [fullName, email, number]);

  orderNewData.line_items = reducedCart;

  if (cart === [] || cart.length === 0) {
    return (
      <>
        <div className="container mb-4">
          <div className="rounded shadow-sm" style={{ backgroundImage: `url(${bannerCheckout})`, backgroundSize: "cover", backgroundPosition: "50%" }}>
            <h4 className="rounded px-4 py-5 bg-primary shadow-sm text-white fw-bold text-center" style={{ "--bs-bg-opacity": 0.75 }}>
              Arka
            </h4>
          </div>
        </div>
        <div className="container my-5" style={{ maxWidth: "500px" }}>
          <div className="shadow-sm p-3 rounded bg-white">
            <div>
              <h3 className="text-center">Shporta është e zbazët</h3>
            </div>
          </div>
          <div className="shadow-sm p-3 rounded mt-3 bg-white">
            <div className="d-grid gap-2">
              <Link className="btn btn-primary " to="/products">
                Shfleto porduktet
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="container mb-4">
        <div className="rounded shadow-sm" style={{ backgroundImage: `url(${bannerCheckout})`, backgroundSize: "cover", backgroundPosition: "50%" }}>
          <h4 className="rounded px-4 py-5 bg-primary shadow-sm text-white fw-bold text-center" style={{ "--bs-bg-opacity": 0.9 }}>
            Arka - Plotësoni të dhënat
          </h4>
        </div>
      </div>
      <div className="container ">
        <Form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-7" id="#section-1">
              {/* <form onSubmit={handleSubmit}> */}
              <div className="shadow-sm p-3 rounded bg-white">
                <h5 className="mb-4">1. Informatat personale*</h5>

                <Form.Group className="pb-3">
                  <Form.Label>Emri dhe mbiemri</Form.Label>
                  <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} isInvalid={formErrors.name} />
                  <Form.Control.Feedback type="invalid">{formErrors.name}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="pb-3">
                  <Form.Label>E-mail adresa</Form.Label>
                  <Form.Control type="text" name="email" value={formData.email} onChange={handleChange} isInvalid={formErrors.email} />
                  <Form.Control.Feedback type="invalid">{formErrors.email}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  Numri i telefonit (04<span className="text-secondary text-opacity-50">4 115 386</span>)<Form.Label></Form.Label>
                  <Form.Control type="tel" name="telephone" maxLength={9} value={formData.telephone} onChange={handleChange} isInvalid={formErrors.telephone} />
                  <Form.Control.Feedback type="invalid">{formErrors.telephone}</Form.Control.Feedback>
                </Form.Group>
              </div>

              <div id="fl_lokacionet" className="shadow-sm p-3 rounded bg-white my-3  ">
                <h5 className="mb-4">2. Në cilën pikë do ta merrni porosinë?*</h5>
                <Form.Group controlId="location">
                  <LocationSelector value={formData.location} onChange={handleChange} error={formErrors.location} isInvalid={formErrors.location} />
                </Form.Group>
              </div>
              <div id="fl_koha" className="shadow-sm p-3 rounded bg-white my-3  ">
                <h5 className="mb-4">3. Kur do ta merrni porosinë?*</h5>
                <Form.Group controlId="pickup">
                  <PickupTimeSelector value={formData.pickup} onChange={handleChange} error={formErrors.pickup} isInvalid={formErrors.pickup} />
                </Form.Group>
                <div className={formData.pickup === "later_today" || formData.pickup === "another_day" ? "ps-2 pt-4" : "d-none"}>
                  <Form.Group className="pb-3">
                    <Form.Label>Zgjedhni kohën</Form.Label>
                    <Form.Control type="time" name="pickup_time" value={formData.pickup_time} onChange={handleChange} isInvalid={formErrors.pickup_time} />
                    <Form.Control.Feedback type="invalid">{formErrors.pickup_time}</Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className={formData.pickup === "another_day" ? "ps-2 pt-2" : "d-none"}>
                  <Form.Group className="pb-3">
                    <Form.Label>Zgjedhni kohën & datën</Form.Label>
                    <Form.Control type="date" name="pickup_date" value={formData.pickup_date} onChange={handleChange} isInvalid={formErrors.pickup_date} />
                    <Form.Control.Feedback type="invalid">{formErrors.pickup_date}</Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-5 d-flex flex-column-reverse flex-md-column">
              <div className="shadow-sm py-3 px-3 rounded  bg-white">
                <div>
                  <h4 className="pb-4">Shporta</h4>
                  <CartItems />
                </div>
                <div className="d-flex flex-row    align-items-center">
                  <div className="me-auto">
                    <h4 className="m-0">Totali</h4>
                  </div>
                  <div>
                    <p className="fw-bold col m-0 fs-5 "> {cartSum.toFixed(2)} € </p>
                  </div>
                </div>
              </div>
              <div className="">
                <BejePorosine />
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}

export default Checkout;
