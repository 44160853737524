import React from "react";
import { Link, NavLink } from "react-router-dom";
// import HomeCarousel from "../components/homeCarousel.js";
// import IndividualIntervalsExample from "../components/carouselSecond.js";
import CategoriesSection from "./categoriesSection.js";
import allCategoriesData from "../data/allCategoriesData.js";
// import HomeBannerCards from "./homeBannerCards.js";
import HomeBannerColumns from "./homeBannerColumns.js";
import { Helmet } from "react-helmet";
const bannerOne = "https://www.cms.furralumi.com/wp-content/uploads/2023/04/furra-lumi-2-interior_1-scaled-1.webp";
const bannerLokacionet = "https://www.cms.furralumi.com/wp-content/uploads/2023/03/FurraLumi_Wide_Lokacjonet_1-scaled.jpg";

function Homepage() {
  return (
    <>
      <Helmet>
        <title>Furra Lumi</title>
      </Helmet>
      {/* <HomeCarousel /> */}
      <div>
        {/* <HomeBannerCards /> */}
        <HomeBannerColumns />

        <CategoriesSection />
        <div className="container-lg">
          {/* <IndividualIntervalsExample /> */}
          <div className="py-5">
            <div className="py-5">
              <div className=" row justify-content-center">
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-chat-square-quote" viewBox="0 0 16 16">
                  <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                  <path d="M7.066 4.76A1.665 1.665 0 0 0 4 5.668a1.667 1.667 0 0 0 2.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 1 0 .6.58c1.486-1.54 1.293-3.214.682-4.112zm4 0A1.665 1.665 0 0 0 8 5.668a1.667 1.667 0 0 0 2.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 1 0 .6.58c1.486-1.54 1.293-3.214.682-4.112z" />
                </svg> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24">
                  <path
                    fill="#e6e9ec"
                    d="M17.175 17q-.75 0-1.15-.637q-.4-.638-.05-1.313L17 13h-2q-.825 0-1.412-.588Q13 11.825 13 11V8q0-.825.588-1.412Q14.175 6 15 6h3q.825 0 1.413.588Q20 7.175 20 8v4.525q0 .225-.038.463q-.037.237-.162.437l-1.425 2.825q-.175.35-.5.55q-.325.2-.7.2Zm-9 0q-.75 0-1.15-.637q-.4-.638-.05-1.313L8 13H6q-.825 0-1.412-.588Q4 11.825 4 11V8q0-.825.588-1.412Q5.175 6 6 6h3q.825 0 1.413.588Q11 7.175 11 8v4.525q0 .225-.037.463q-.038.237-.163.437L9.375 16.25q-.175.35-.5.55q-.325.2-.7.2Z"
                  />
                </svg>
              </div>
              <p className="fs-2 fst-italic pt-4 text-center mx-auto" style={{ maxWidth: "450px", color: "#97694e" }}>
                Përjetoni shijen e produkteve punëdore, të gatuara si në shtëpi, me kujdes dhe dashuri.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="py-5">
        <div className="container">
          <div className=" bg-primary p-4 rounded-4">
            <div className="row">
              <div className="col-12 col-lg-6 mb-5 mb-lg-0">
                <div className="ratio ratio-4x3 rounded-4" style={{ backgroundImage: `url(${bannerOne})`, backgroundSize: "cover" }}></div>
                {/* <img alt=""  src={bannerOne} className="img-fluid rounded ratio ratio-1x1"></img> */}
              </div>
              <div className="col-12 col-lg-6 text-center justify-content-center align-self-center text-white">
                <h2 className="fw-bold">Porosit, mos prit!</h2>
                <div className="row justify-content-center">
                  <p className="col-10 col-xl-6 fs-3">Porosit paraprakisht dhe mos prit n'rend. Gati për 40 minuta.</p>
                </div>
                <Link className="btn btn-outline-light" to="/products">
                  Shiko produktet
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="container p-2 my-5">
          <div className="p-0 m-0 rounded">
            <div className=" row p-0 m-0 py-5 rounded">
              <div className="col-12  mx-auto py-5 text-center my-5  align-self-center text-white">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-question-square-fill" viewBox="0 0 16 16">
                  <path
                    fill="#98694C"
                    d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.496 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.803 0-1.253.478-1.342 1.134-.018.137-.128.25-.266.25h-.825zm2.325 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927z"
                  />
                </svg>

                <h1 className="fw-bolder py-4" style={{ color: "#98694C" }}>
                  Keni ndonjë pyetje?
                </h1>

                <Link className="btn btn-light " to="/faqs">
                  Klikoni këtu
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container p-2 my-5 ">
          <div className="p-0 m-0 rounded-4" style={{ backgroundImage: `url(${bannerLokacionet})`, backgroundSize: "cover", backgroundPositionX: "60%" }}>
            <div className=" row p-0 m-0 py-5 rounded-4 bg-primary" style={{ "--bs-bg-opacity": 0.5 }}>
              <div className="col-12 col-xl-4 mx-auto my-5 py-5 text-center  align-self-center text-white">
                <svg className="mt-5" xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                  <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                </svg>

                <h1 className="fw-bolder py-4">Mund të na gjeni në dy lokacione!</h1>

                <Link className="btn btn-outline-light" to="/locations">
                  Lokacionet
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Homepage;
