import { React, useEffect, useState } from "react";
import WooAPI from "./woocommerce-api";

function FetchAllProductsData() {
  const [productId, setProductId] = useState(0);

  // useEffect(() => {
  //   fetchProducts();
  // }, []);

  function fetchProductVariationData() {
    // WooAPI.get("products/" + productId + "/variations")
    WooAPI.get("products/150")
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
        }
      })
      .catch((error) => {});
  }

  // let fetchProducts = () => {
  //   WooAPI.get("products/categories")
  //     .then((response) => {
  //       if (response.status === 200) {
  //         console.log(response.data);
  //       }
  //     })
  //     .catch((error) => {});
  // };

  function handleNumberChange(event) {
    if (event.target.value) {
      setProductId(parseInt(event.target.value));
    }
  }

  return (
    <>
      {/* <Header /> */}

      <div className="container">
        <div className="row">
          <h2>Fetched Product Data with RestAPI</h2>
        </div>
        <div className="col-4 px-1 ">
          <div className="pb-0">
            <input type="text" id="addToCart" value={productId} onChange={handleNumberChange} className="form-control true text-center " aria-describedby="nameHelpBlock" />
            <div id="nameHelpBlock" className="form-text"></div>
          </div>
        </div>
      </div>
      <div className="d-grid gap-2">
        <button className="btn btn-primary " onClick={fetchProductVariationData}>
          Kërko
        </button>
      </div>
      <br />
      {/* <Footer /> */}
    </>
  );
}

export default FetchAllProductsData;
