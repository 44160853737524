import React from "react";
import { useContext } from "react";
import { CartContext } from "./cartComponent";

export function CartItems() {
  const { cart, cartSize, clearCart, reducedCart, removeItemFromCart, cartSum, addItemToCart } = useContext(CartContext);

  let handleRemove = (itemIndex) => {
    removeItemFromCart(itemIndex);
  };

  const handlePlus = (key) => {
    addItemToCart(cart[key], cart[key].step, 1);
  };

  const handleMinus = (key) => {
    if (cart[key].quantity > 1) {
      addItemToCart(cart[key], cart[key].step, -1);
    }
  };
  console.log(cart);

  return (
    <div style={{ width: "100%" }} className="mb-auto CartScrollbarEdited overflow-y-auto">
      {/* <p className="text-break">{JSON.stringify(cart)}</p> */}
      {cart.map((items, key) => {
        return (
          <div className=" pt-2 pb-4 mx-0 px-0 row mb-4 mt-0  border-bottom" key={items.product_id}>
            <div className="col ps-0 pe-2 " style={{ maxWidth: "90px" }}>
              <div className="ratio ratio-1x1 rounded" style={{ backgroundImage: `url(${items.image})`, backgroundSize: "cover", backgroundPosition: "50%" }}></div>
              {/* <div className="position-relative"> */}
              {/* {items.image ? <img alt=""  src={items.image} style={{ width: "100%" }} className="rounded"></img> : <img alt=""  src={defaultImg} className="rounded" style={{ width: "100%" }}></img>} */}

              {/* <button className="btn btn-light p-0 pb-1 ps-1 pe-1 m-0 position-absolute top-0 start-0 " value={key} onClick={() => handleRemove(key)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                      </button> */}
              {/* </div> */}
            </div>
            <div className="col ps-1 pe-0">
              <div className="position-relative pb-3">
                <p className="p-0 m-0 pe-4  col">
                  {items.name}
                  <span className="pb-2 text-secondary">{items.variation_name === "" ? "" : " - " + items.variation_name}</span>
                </p>
                {/* <p className="p-0 m-0 pe-4 pb-2 col" style={{ fontSize: "12px" }}>
                  ID: {items.product_id}
                  <br />
                  Variation id: {items.variation_id}
                </p> */}
                <p className=" col m-0 text-secondary " style={{ fontSize: "14px" }}>
                  {(items.price * 1 * items.step).toFixed(2)} €
                </p>
                <button className="btn btn-light p-0 pb-1 ps-1 pe-1 m-0 position-absolute top-0 end-0" value={key} onClick={() => handleRemove(key)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </button>
              </div>
              <div className="d-flex flex-row align-items-end pt-2">
                <div className=" d-flex flex-row align-items-center  me-auto">
                  <button className="btn btn-light ps-0 pe-2 py-0 m-0 border-0 minus bg-white" value={key} onClick={() => handleMinus(key)}>
                    <span className="p-0 m-0">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="23" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16">
                        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                      </svg>
                    </span>
                  </button>
                  <div className="">
                    <p className="fw-bold p-0 m-0 px-2">{items.quantity}</p>
                  </div>
                  <button className="btn btn-light px-2 py-0 m-0 border-0 plus bg-white" value={key} onClick={() => handlePlus(key)}>
                    <span className="p-0 m-0">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="22" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                      </svg>
                    </span>
                  </button>
                </div>
                <div className="row ">
                  <p className="fw-bold col m-0 "> {(items.price * 1 * items.quantity * items.step).toFixed(2)} €</p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
