function ThankYou({ props }) {
  return (
    <>
      <div className="container my-5 p-5 text-center" style={{ color: "#98694c" }}>
        <h2 className="text-center">Ju faleminderit për prorosinë!</h2>
        <p className="pb-5">Porosia u morr.</p>
        <h5 className="px-5">Tani do të pranoni një thirrje për konfirmimin e porosisië tuaj, dhe së shpejti do ta pranoni një email.</h5>
      </div>
    </>
  );
}

export default ThankYou;
