import { useEffect } from "react";

function RaiffeisenMenu() {
  useEffect(() => {
    // Redirect the user to a new URL
    window.location.href = "/assets/FL_20240910_Raiffeisen_Menu.pdf";
  }, []);
  return null;
  return (
    <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
      <iframe src="/assets/FL_20240910_Raiffeisen_Menu.pdf" title="Menu PDF" style={{ width: "100%", height: "100%" }} frameBorder="0" />
    </div>
  );
}

export default RaiffeisenMenu;
